import React, { } from 'react';

import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemText from '@mui/material/ListItemText';
import TextField from '@mui/material/TextField';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import { FixedSizeList } from 'react-window';

import AutoSizer from 'react-virtualized-auto-sizer';

import CircularProgress from '@mui/material/CircularProgress';

import PropTypes from 'prop-types';

import '../styles/header.scss';

VirtualizedList.propTypes = {
  data: PropTypes.array,
  onClick: PropTypes.func,
  selected: PropTypes.object,
  onSearch: PropTypes.func,
  loading: PropTypes.bool,
};

function renderRow(props) {
  const { index, style, data } = props;

  const user = data.data[index];

  if (user.tag === '')
    return;

  return (
    <ListItem style={style} key={index} component="div">
      <ListItemButton divider onClick={e => data.onClick(e, user)} selected={data.selected === user}>
        <ListItemText sx={{ m: 0 }} color='selected' primary={user.tag} />
      </ListItemButton>
    </ListItem>
  );
}

/**
 * Will render a list of components on the current page.
 *
 * Props:
 * data [array] - All of the data elements to be included in list.
 * onClick [func] - What to do when a list element is clicked
 * selected [object] - Which data element was last clicked
 * onSearch [func] - Function callback to filter list
 * loading [bool] - Renders a loading bar when true.
 *
 * @param {*} props
 * @returns
 */

export default function VirtualizedList(props) {
  const windowHeight = window.innerHeight;
  const listHeight = windowHeight * 0.7; // set the list height to 70% of the window height

  const filteredData = props.data.filter((tag) => tag.tag !== '');

  return (
    <AutoSizer>
      {({ width, height }) => (
        <Box>
          <ListItem
            sx={{ width: width, height: height * .15 }}
          >
            <TextField
              label="Search"
              variant="outlined"
              size="small"
              fullWidth
              sx={{ width: width, boxShadow: 1, height: 'auto' }}
              onChange={props.onSearch}
            />
          </ListItem>
          {props.loading && <CircularProgress />}
          {!props.loading && (
            <FixedSizeList
              height={height * .85}
              width={width}
              itemSize={40}
              itemCount={filteredData.length || 0}
              overscanCount={5}
              itemData={{
                data: filteredData,
                onClick: props.onClick,
                selected: props.selected,
              }}
            >
              {renderRow}
            </FixedSizeList>
          )}
        </Box>
      )}
    </AutoSizer>
  );
}
