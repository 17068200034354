import React from 'react';
import '../App.css';
import { Link } from 'react-router-dom';

import Button from '@mui/material/Button';
import { Typography } from '@mui/material';
import { createTheme, responsiveFontSizes, ThemeProvider } from '@mui/material/styles';


let theme = createTheme();
theme = responsiveFontSizes(theme);

/**
 * Layout for the website that the homeowner will use to access their videos
 * @returns HomeOwner main page for the website
 */

const HomeScreen = () => {

  return (<>
    <ThemeProvider theme={theme}>
      <div>
        <div className="App">
          <Typography variant="h5">Home Page </Typography><br />
          <Link to="/HomeOwner">
            <Button>
              Evaluations (Needs to be the *new* evaluations page (NOT VIDEO PAGE)) (DEV ROUTE)
            </Button>
          </Link><br />
          <Link to="/EvaluatorMainPage/all">
            <Button>
              See Evaluators + Homeowners
            </Button>
          </Link><br />
          <Link to="/MyHomes">
            <Button>
              /MyHomes
            </Button>
          </Link><br />
          <Link to="/EvalMainPage">
            <Button>
              Evaluator Main Page
            </Button>
          </Link><br />
          {/* <Link
            color={props.active == 'logout' ? 'black' : 'inherit'}
            underline="hover"
            href='#'
            onClick={() => logout()}>
            Logout
          </Link> */}
        </div>
      </div>
    </ThemeProvider>

  </>);
};

export default HomeScreen;
