import axios from 'axios';

let session = localStorage.getItem('session');
if (!localStorage.getItem('remember'))
  session = sessionStorage.getItem('session');

// Create an axios instance with a custom config
// baseURL is the common URL for all requests
const ApiClient = axios.create({
  // Use the REACT_APP_API_URL environment variable to override the API URL
  // @ts-ignore - process.env
  baseURL: process.env.REACT_APP_API_URL,

  // response type - default is 'json'
  responseType: 'json',

  // `headers` are custom headers to be sent
  headers: {
    // User-Agent - The name of the application making the request with the version number
    // Web client shouldn't use the User-Agent header (the browser will set it automatically)
    // 'User-Agent': 'Inspector-Web / 0.1',

    // The content type of the request (default is application/json)
    'Content-Type': 'application/json',

    'Authorization': `Bearer ${session}`
  },
});

// @ts-ignore
ApiClient.isCancel = axios.isCancel;

// Save the original axios methods
// @ts-ignore
ApiClient.original_methods = {
  get: ApiClient.get,
  post: ApiClient.post,
  put: ApiClient.put,
  delete: ApiClient.delete,
};

// Override the axios methods to replace the parameters in the url with the values
// in the config object
ApiClient.get = (url, config) => send_request('get', url, null, config);
ApiClient.post = (url, data, config) => send_request('post', url, data, config);
ApiClient.put = (url, data, config) => send_request('put', url, data, config);
ApiClient.delete = (url, config) => send_request('delete', url, null, config);


/**
 * Send a request using the original axios methods after replacing the parameters
 *
 * @param {string} method - The method to use (get, post, put, delete)
 * @param {string} url - The url to send the request to (ex: /users/:id)
 * @param {object} data - The data to send with the request
 * @param {object} config - The config object passed to the axios request
 * @returns {Promise} - The axios promise
 */
function send_request(method, url, data, config) {
  if (url === '/log') {
    // @ts-ignore
    return ApiClient.original_methods[method](url, data, config);
  }

  let updated_url = get_processed_url(url, config);
  console.log('ApiClient.' + method + '() - url: ' + updated_url);

  // forward the request to the original axios method
  // @ts-ignore
  return ApiClient.original_methods[method](updated_url, data, config);
}

/**
 * Replace the parameters in the url with the values in the config object
 * ex: /users/:id/roles/:role_id => /users/1/roles/2
 *
 * @param {string} url - The url to process (ex: /users/:id)
 * @param {object} config - The config object passed to the axios request
 * @returns {string} - The processed url
 */
function get_processed_url(url, config) {
  if (config === undefined) {
    config = {};
  }

  // find all the parameters in the url using regex
  const parameters = url.match(/:[a-zA-Z0-9_]+/g);

  let updated_url = url;
  if (parameters && parameters.length > 0) {

    parameters.forEach(parameter => {
      // remove the colon from the parameter
      const key = parameter.slice(1);

      // check if the given config contains the parameter
      if (Object.prototype.hasOwnProperty.call(config, key)) {
        updated_url = updated_url.replace(parameter, config[key]);
      } else {
        console.log(`Missing parameter: ${key} for url: ${url}`);
      }
    });

  }
  return updated_url;
}


export default ApiClient;
