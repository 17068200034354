import React, { useRef, useEffect, useState, useCallback } from 'react';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import Dialog from '@mui/material/Dialog';
import Radio from '@mui/material/Radio';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import Box from '@mui/material/Box';
import LinearProgress from '@mui/material/LinearProgress';
import InputMask from 'react-input-mask';
import FormControl from '@mui/material/FormControl';

import Alert from '@mui/material/Alert';
import AlertTitle from '@mui/material/AlertTitle';

import DropDown from '../components/DropDown';

import PropTypes from 'prop-types';
import { Checkbox, FormControlLabel, RadioGroup, Typography } from '@mui/material';

FormDialog.propTypes = {
  open: PropTypes.bool,
  contextText: PropTypes.string,
  title: PropTypes.string,
  buttonText: PropTypes.string,
  onClose: PropTypes.func,
  onSubmit: PropTypes.func,
  fields: PropTypes.array,
  loading: PropTypes.bool,
  requiredText: PropTypes.string,
  noConfirmButton: PropTypes.bool,
  closeText: PropTypes.string,
  errorMessage: PropTypes.string
};

RenderScreen.propTypes = {
  data: PropTypes.array,
  onDropDownChange: PropTypes.func,
  onCheckBoxChange: PropTypes.func,
};

function RenderScreen(props) {
  return props.data.map((val, idx) => {
    if (!val.type) {
      const required = val.label ? val.required : true;
      const label = val.label ? val.label : val;

      if (val.header) {
        return (
          <Typography
            key={idx}
            variant='h6'
            sx={{ mt: '2%' }}
          >
            {val.header}
          </Typography>
        );
      } else if (val.display === 'label') {
        return (<>
          <FormControl key={idx} sx={{ mt: 2, width: '100%' }}>
            <Typography
              key={idx}
              variant='h7'
              color='grey'
            >
              {val.label}
            </Typography>
            <Typography
              key={idx}
              variant='h6'
              sx={{
                height: 'max-content',
                overflowY: 'auto',
                border: '1 solid'
              }}
            >
              {val.default || 'N/A'}
            </Typography>
          </FormControl>
        </>);
      } else if (!val.default) {
        return (
          <TextField
            name={val.id}
            key={val.id}
            margin="dense"
            id={val.id}
            inputRef={val.ref}
            label={label}
            fullWidth
            variant="standard"
            disabled={val.locked}
            required={required}
            color='info'
            onChange={e => {
              if (val.onInput)
                val.onInput(e, val.id);
            }}
          />
        );
      } else {
        return (
          <TextField
            name={val.id}
            key={val.id}
            margin="dense"
            id={val.id}
            label={val.label}
            defaultValue={val.default}
            disabled={val.locked}
            fullWidth
            variant="standard"
            required
            select={false}
            onChange={e => {
              if (val.onInput)
                val.onInput(e, val.id);
            }}
            color='info'
          />
        );
      }
    }
    if (val.type == 'dropdown') {
      const def = val.default ? val.default : 'Homeowner';
      return (
        <DropDown
          key={idx}
          entries={val.entries}
          label={val.label}
          default={def}
          onChange={e => {
            if (props.onDropDownChange) {
              props.onDropDownChange(e);
            }
            if (val.onInput) {
              val.onInput(e, val.id, 'dropdown');
            }
          }}
          disabled={val.locked}
          name={val.id}
        />
      );
    } else if (val.type == 'phone') {
      // console.log('TESTING');
      const required = val.label ? val.required : true;
      const label = val.label ? val.label : val;
      return (
        <InputMask
          key={idx}
          mask="(999) 999 9999"
          disabled={false}
          maskChar=" "
          defaultValue={val.default}
        >
          {() => <TextField
            name={val.id}
            key={val.id}
            autoFocus
            margin="dense"
            id={val.id}
            fullWidth
            defaultValue={val.default}
            variant="standard"
            label={label}
            ref={val.ref}
          />}
        </InputMask>
      );
    } else if (val.type == 'check') {
      // console.log(props);
      const onChange = props.onCheckBoxChange;

      return (
        <RadioGroup key={idx}>
          <FormControlLabel value="sameAddress" control={<Checkbox
            defaultChecked={true} onChange={e => {
              if (onChange) {
                onChange(e);
              }
            }}
          />} label="Billing Address Same as Evaluation Address" />
        </RadioGroup>
      );
    }
  });
}

/**
 * Creates a modal that will allow the user to enter information into.
 * Acts as an html form so the user doesn't have to create entire pages.
 *
 * To retrieve information from the Dialog:
 * 1) Create a formData object.
 *    - Note that event.target will be passed through from the onSubmit callback.
 *           new FormData(event.target);
 *
 * 2) Access the unique identifiers of each form data element.
 *          formData.get(id);
 *
 * Props:
 * open (bool) - Opens the modal
 * title (string) - Name displayed at the top of the modal
 * contextText (string) - Label displayed under the title
 * buttonText (string) - Text displayed for confirming a form submission
 * onClose (func) - Callback when the user closes the form
 * onSubmit (func) - Callback when the user submits the form
 * fields (array) - All data fields contained in the form
 * loading (bool) - Displays a loading animation when set to true
 * requiredText (string) - Text that will display to notify the user what is required (similar to contextText)
 * noConfirmButton (bool) - If set to false, no submit button will be displayed
 * closeText (string) - Custom close button text
 *
 *
 * Example a fields array in the props:
 * fields = [
 *  {
 *    // Note that you can have multiple data fields per form.
 *    data: [
 *      { header: 'Homes' },
 *      { label: 'Address1', required: true, onInput: exampleCallback }
 *      ...
 *    ],
 *
 *    ...
 *  }
 * ]
 *
 * @param {*} props
 * @returns
 */
export default function FormDialog(props) {
  const [open, setOpen] = useState(false);
  const [dropCond, setDropCond] = useState('');
  const [checkCond, setCheckCond] = useState(false);
  const descriptionElementRef = useRef(null);

  if (props.open != open) {
    setOpen(props.open);
  }

  const setDescriptionElementRef = useCallback((node) => {
    if (node !== null) {
      descriptionElementRef.current = node;
      descriptionElementRef.current.scrollTo(0, 0);
      descriptionElementRef.current.focus();
    }
  }, []);

  const onDropDownChange = (e) => {
    const { value } = e.target;

    setDropCond(value);
  };

  const onCheckBoxChange = (e) => {
    if (e.target.checked) {
      setCheckCond(false);
    } else {
      setCheckCond('SameBilling');
    }
  };

  return (
    <div>
      {open && (
        <Dialog fullWidth open={open} scroll='body' sx={{ overflow: 'hidden' }}>
          <DialogTitle>{props.title}</DialogTitle>
          <form onSubmit={props.onSubmit}>
            <DialogContent
              sx={{ maxHeight: '400px', overflowY: 'scroll' }}
              scroll='body'
              ref={setDescriptionElementRef}
              dividers={true}>
              {props.errorMessage &&
                <Alert sx={{ m: '1%' }} severity="error">
                  <AlertTitle>{props.errorMessage}</AlertTitle>
                </Alert>
              }
              <DialogContentText
                tabIndex={-1}>
                {props.contextText}
              </DialogContentText>
              <DialogContentText sx={{ fontSize: '13px' }}>
                <br />
                {props.requiredText}
              </DialogContentText>
              {props.fields.map((val, idx) => {
                if (!val.type && !val.condition) {
                  return (
                    <RenderScreen key={idx} data={val.data} onDropDownChange={onDropDownChange} onCheckBoxChange={onCheckBoxChange} />
                  );
                } else {
                  console.log(checkCond, val.condition);
                  if ((dropCond === val.condition || checkCond === val.condition) && val.visibility === dropCond) {
                    return (<RenderScreen key={idx} data={val.data} onCheckBoxChange={onCheckBoxChange} />);
                  }
                }
              })}
            </DialogContent>
            <DialogActions>
              <Button disabled={props.loading} onClick={props.onClose}>{props.closeText || 'Cancel'}</Button>
              {!props.noConfirmButton && <Button disabled={props.loading} type='submit'>{props.buttonText}</Button>}
            </DialogActions>
          </form>
          {props.loading && <Box sx={{ width: '100%' }}>
            <LinearProgress />
          </Box>
          }
        </Dialog>
      )}
    </div>);
}
