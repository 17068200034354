import React, { useState, useEffect } from 'react';
import '../App.css';
import Requests from '../Requests';

import ApiClient from '../ApiClient';

import Typography from '@mui/material/Typography';
import { createTheme, responsiveFontSizes, ThemeProvider } from '@mui/material/styles';

import SessionNav from '../components/SessionNavigation';

let theme = createTheme();
theme = responsiveFontSizes(theme);

/**
 * Layout for the website that the homeowner will use to access their videos
 * @returns HomeOwner main page for the website
 */
const EvaluatorMainPage = () => {
  const [data, setData] = useState([]);

  const [loaded, setLoaded] = useState(false);
  const [userInfo, setUserInfo] = useState({});

  /**
   * Fetches user data
   */
  const fetch_data = async () => {
    const response = await ApiClient.get(Requests.getUserById, { id: userInfo.user_id });

    if (response.status == 200) {
      console.log(response);
    }
  };

  useEffect(() => {
    setLoaded(true);
  }, []);

  useEffect(() => {
    if (loaded) {
      fetch_data();
    }
  }, [loaded]);

  return (<>
    <div className="homeowner">
      <SessionNav /><br />
      <ThemeProvider theme={theme}>
        <Typography variant='h4'>Edit</Typography>

      </ThemeProvider>
    </div>
  </>);
};

export default EvaluatorMainPage;
