import React, { useState } from 'react';
import ApiClient from '../ApiClient';
import Requests from '../Requests';

import Navigation from '../components/Navigation';
import SubmitButton from '../components/SubmitButton';

import { Typography } from '@mui/material';
import { createTheme, responsiveFontSizes, ThemeProvider } from '@mui/material/styles';
import FormControl from '@mui/material/FormControl';
import InputLabel from '@mui/material/InputLabel';
import OutlinedInput from '@mui/material/OutlinedInput';
import MuiAlert from '@mui/material/Alert';
import Link from '@mui/material/Link';
const AWS = require('aws-sdk');

import '../styles/reset.scss';

// Center the Alert Message
const alertCSS = {
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center'
};

const customStyle = {
  backgroundColor: 'transparent',
  color: 'black',
};

// Error Component Styling
const Alert = React.forwardRef(function Alert(props, ref) {
  return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

let theme = createTheme();
theme = responsiveFontSizes(theme);

const Login = () => {
  const [username, setUsername] = useState('');
  const [apiCall, setApiCall] = useState(false);

  // Material UI Alert Component Props
  const [severity, setSeverity] = React.useState('');
  const [displayAlert, setDisplayAlert] = React.useState(false);
  const [alertMessage, setAlertMessage] = React.useState('Login not Found');

  const messages = {
    API: 'An Error Occurred on Our Server',
    '404': 'Email Not Found',
    Empty: 'Missing Email',
    Success: 'Email Sent!'
  };

  const send_reset = async () => {
    if (username == '') {
      setSeverity('warning');
      setDisplayAlert(true);
      setAlertMessage(messages.Empty);
      return;
    }

    try {
      setApiCall(true);

      const response = await ApiClient.post(
        Requests.sendReset,
        { email: username }
      );

      setApiCall(false);

      console.log(response.data);

      if (response.data) {
        setSeverity('success');
        setDisplayAlert(true);
        setAlertMessage(messages.Success);
        window.location.href = '/';
      } else {
        setSeverity('error');
        setDisplayAlert(true);
        setAlertMessage(messages['404']);
      }

    } catch (err) {
      console.log(err);
    }
  };


  const return_to_login = () => {

  };

  return (<>
    {/* <Navigation active='reset' /> */}
    <div className='login_body'>
      <ThemeProvider theme={theme}>
        {displayAlert && <Alert sx={alertCSS} severity={severity}> {alertMessage} </Alert>}<br />
        <MuiAlert sx={{ fontWeight: 'lighter' }} severity='warning' style={customStyle}>
          {'Enter your Email, and We\'ll Send you a Reset Link!'}
        </MuiAlert><br />
        <Typography variant="h4">Enter Email</Typography><br />
        <FormControl sx={{ m: 1, width: '25ch' }} variant="outlined">
          <InputLabel htmlFor="outlined-adornment-password">Email</InputLabel>
          <OutlinedInput
            required
            id="outlined-adornment-username"
            onChange={e => setUsername(e.target.value)}
            label="Password"
          />
        </FormControl><br /><br />
        <SubmitButton
          disabled={apiCall}
          loading={apiCall}
          onClick={() => send_reset()}
          label="send"
        />
        <Link
          underline="hover"
          href='/'>
          Return to Login
        </Link>
      </ThemeProvider>
    </div>
  </>);
};

export default Login;
