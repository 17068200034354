// ./components/FormInput.js
import React, { useState } from 'react';

import Breadcrumbs from '@mui/material/Breadcrumbs';
import Link from '@mui/material/Link';
import Requests from '../Requests';
import ApiClient from '../ApiClient';

import PropTypes from 'prop-types';

import ConfirmationDialog from '../components/ConfirmationDialog';

const confirmationLogoutText = 'Are you sure you want to logout?';

Password.propTypes = {
  active: PropTypes.string,
  style: PropTypes.object
};

/**
 * Navigation for users who are logged in
 *
 * Composed of a logout button and home button.
 *
 * @param {} props
 * @returns
 */
function Password(props) {
  const session = localStorage.getItem('session');
  const user_info = localStorage.getItem('userInfo');

  const [invalidUser, setInvalidUser] = useState(false);
  const [logoutConfirm, setLogoutConfirm] = useState(false);
  const [loading, setLoading] = useState(false);

  const logout = async () => {
    setLoading(true);

    const response = await ApiClient.put(
      Requests.logout,
      { session, user_info }
    );

    if (response.data) {
      // Clear Local Storage if RememberMe = true
      localStorage.removeItem('session');
      localStorage.removeItem('userInfo');

      // Clear Session Storage if RememberMe = false
      sessionStorage.removeItem('session');
      sessionStorage.removeItem('userInfo');

      window.location.href = '/';
    }

    setLoading(false);
  };

  return (
    <>
      <ConfirmationDialog
        text={confirmationLogoutText}
        header={'Logout'}
        loading={loading}
        onClose={() => { setLogoutConfirm(false); }}
        onConfirm={logout}
        visible={logoutConfirm}
        confirmText={'Logout'}
      />
      <Breadcrumbs style={props.style} aria-label="breadcrumb" className='breadcrumb'>
        <Link
          disabled={props.active == 'home' ? 'black' : 'inherit'}
          underline="hover"
          color={props.active == 'home' ? 'black' : 'inherit'}
          href="/menu">
          Home
        </Link>
        {/*  <Link
          color={props.active == 'edit' ? 'black' : 'inherit'}
          underline="hover"
          href="/HomeOwner">
          Homeowner Page
        </Link> */}
        <Link
          color={props.active == 'logout' ? 'black' : 'inherit'}
          underline="hover"
          href='#'
          onClick={() => { setLogoutConfirm(true); }}>
          Logout
        </Link>
      </Breadcrumbs>
    </>
  );
}
export default Password;
