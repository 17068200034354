import * as React from 'react';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import LinearProgress from '@mui/material/LinearProgress';

import PropTypes from 'prop-types';


MaxWidthDialog.propTypes = {
  text: PropTypes.string,
  header: PropTypes.string,
  visible: PropTypes.bool,
  onClose: PropTypes.func,
  onConfirm: PropTypes.func,
  loading: PropTypes.bool,
  confirmText: PropTypes.string,
};

/**
 * A modal that will prompt the user with two options.
 *
 * Props:
 * text (string) - Text displayed on modal
 * header (string) - Title of modal
 * visible (bool) - Whether the modal is open to the user
 * onClose (function) - Callback function when the user hits the cancel option
 * onConfirm (function) - Callback function when the user hits the confirm option
 * loading (bool) - Will trigger a loading animation. Typically used on API Calls.
 * confirmText (string) - Text that is displayed for the confirmation. By default is "Confirm"
 *
 * @param {*} props
 * @returns
 */
export default function MaxWidthDialog(props) {
  const fullWidth = true;
  const maxWidth = 'xs';

  return (
    <React.Fragment>
      <Dialog
        fullWidth={fullWidth}
        maxWidth={maxWidth}
        open={props.visible}
        onClose={props.onClose}
      >
        <DialogTitle>{props.header}</DialogTitle>
        <DialogContent>
          <DialogContentText>
            {props.text}
          </DialogContentText>
          <Box
            noValidate
            component="form"
            sx={{
              display: 'flex',
              flexDirection: 'column',
              m: 'auto',
              width: 'fit-content',
            }}
          >
          </Box>
        </DialogContent>
        <DialogActions>
          <Button disabled={props.loading} onClick={props.onClose}>Cancel</Button>
          <Button disabled={props.loading} onClick={props.onConfirm}>{props.confirmText || 'Confirm'}</Button>
        </DialogActions>
        {props.loading &&
          <Box sx={{ width: '100%' }}>
            <LinearProgress />
          </Box>
        }
      </Dialog>
    </React.Fragment>
  );
}
