import React, { useEffect } from 'react';

import NativeSelect from '@mui/material/NativeSelect';
import FormControl from '@mui/material/FormControl';
import InputLabel from '@mui/material/InputLabel';

import PropTypes from 'prop-types';


MaxWidthDialog.propTypes = {
  entries: PropTypes.array,
  label: PropTypes.string,
  default: PropTypes.string,
  name: PropTypes.string,
  onChange: PropTypes.func,
  disabled: PropTypes.bool
};

/**
 * Renders a dropdown menu
 *
 * Props:
 * entries (array) - Data values contained in the dropdown
 * label (string) - Label defining what the dropdown is displaying
 * default (string) - Default value of the dropdown (must be in the array of entries)
 * name (string) - Identifier
 * onChange (func) - Callback whenever a dropdown node is selected
 * disabled (bool) - If disabled, the user can't select any entries.
 *
 * @param {*} props
 * @returns
 */
export default function MaxWidthDialog(props) {
  // Render the default immediately
  useEffect(() => {
    if (props.onChange)
      props.onChange({ target: { value: props.default } });
  }, []);

  return (
    <FormControl sx={{ display: 'flex', minWidth: 80, mt: 3, mb: 3 }}>
      <InputLabel required id="demo-simple-select-autowidth-label">{props.label}</InputLabel>
      <NativeSelect
        name={props.name}
        id="demo-simple-select-autowidth"
        defaultValue={props.default}
        label={props.label}
        onChange={props.onChange}
        onLoad={props.onChange}
        disabled={props.disabled}
        required
      >
        {props.entries.map((val, idx) => {
          return (
            <option key={idx} value={val}>{val}</option>
          );
        })}
      </NativeSelect>
    </FormControl>
  );
}
