// @ts-ignore
import React, { useEffect, useState } from 'react';

import './App.css';

import './styles/landing.scss';
import './styles/reset.scss';
import './styles/homeowner.scss';

import { BrowserRouter, Routes, Route } from 'react-router-dom';

import ResetPassword from './pages/ResetPassword';
import HomeOwner from './pages/HomeOwner';
import Login from './pages/Login';
import VideoWeb from './pages/VideoWeb';
import SendReset from './pages/SendReset';
import EvaluatorMainPage from './pages/BossEvaluatorMainPage';
import LandingPage from './pages/LandingPage';
import EditUserPage from './pages/EditUser';
import MyHomes from './pages/MyHomes';
import EvalMainPage from './pages/EvaluatorMainPage';
import EditTags from './pages/EditTags';
import Utils from './utils/Utils';

// Example of how to use the ApiClient
// async functions needs to be used with the await keyword
async function getRoles() {
  // const response = await ApiClient.get('/roles');
  // console.log(response.data);
}


getRoles(); // needs more research on how to use async functions with React `create-react-app`

function App() {
  const [loadPaths, setLoadPaths] = useState(false);

  const sessionData = Utils.fetchSessionData();

  useState(() => {
    // User is not logged in, redirect them to the login
    if (!sessionData.userInfo
      && window.location.pathname !== '/'
      && !window.location.pathname.includes('reset')
    ) {
      window.location.href = '/';
    } else {
      setLoadPaths(true);
      console.log(sessionData.userInfo);
      if (sessionData.userInfo && sessionData.userInfo.email === 'Firsteval@gmail.com') {
        // import('./styles/hasbullah.scss').then(() => {
        //   // The CSS module has been loaded and executed
        // });
      }
    }
  }, []);

  useEffect(() => {
    if (loadPaths) {
      const sessionData = Utils.fetchSessionData();

      const handleRouteChange = () => {
        if (sessionData.userInfo) {
          setLoadPaths(true);
        }
      };

      window.addEventListener('popstate', handleRouteChange);

      return () => {
        window.removeEventListener('popstate', handleRouteChange);
      };
    }
  }, []);

  return (<>
    <div>
      <br />
      <BrowserRouter>
        <Routes>
          <Route path='/' element={<Login />} />
          <Route path='/send_reset' element={<SendReset />} />
          <Route path='/reset/:uuid' element={<ResetPassword />} />

          {loadPaths &&
            <>
              <Route path='/HomeOwner' element={<HomeOwner />} />
              <Route path='/EvaluatorMainPage/:route' element={<EvaluatorMainPage />} />
              <Route path='/users/edit/:user_id' element={<EditUserPage />} />
              <Route path='/login' element={<Login />} />
              <Route path='/home/:house_id/:defaultVid?' element={<VideoWeb />} />
              <Route path='/menu' element={<LandingPage />} />
              <Route path='/MyHomes' element={<MyHomes />} />
              <Route path='/EvalMainPage/:route' element={<EvalMainPage />} />
              <Route path='/EditTags' element={<EditTags />} />
            </>
          }
        </Routes>
      </BrowserRouter>
    </div>
  </>);
}

export default App;
