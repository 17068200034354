import React from 'react';
import TreeView from '@mui/lab/TreeView';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import TreeItem, { useTreeItem } from '@mui/lab/TreeItem';
import PauseIcon from '@mui/icons-material/Circle';

import '../styles/animatePlay.scss';

import PropTypes from 'prop-types';

import clsx from 'clsx';
import Typography from '@mui/material/Typography';

MultiSelectTreeView.propTypes = {
  tree: PropTypes.object,
  onNodeToggle: PropTypes.func,
  currVideo: PropTypes.string,
  onNodeSelect: PropTypes.func,
  expanded: PropTypes.array,
  selected: PropTypes.array,
  searchVal: PropTypes.string
};

const CustomContent = React.forwardRef(function CustomContent(props, ref) {
  const { classes, className, label, nodeId, icon: iconProp, expansionIcon, displayIcon, handleNodeToggle } = props;
  const { disabled, expanded, selected, focused, handleExpansion, handleSelection } = useTreeItem(nodeId);

  const icon = iconProp || expansionIcon || displayIcon;

  const handleExpansionClick = (event) => {
    handleExpansion(event);
  };

  const handleSelectionClick = (event) => {
    handleSelection(event);
  };

  return (
    <div
      className={clsx(className, classes.root, {
        [classes.expanded]: expanded,
        [classes.selected]: selected,
        [classes.focused]: focused,
        [classes.disabled]: disabled,
      })}
      ref={ref}
    >
      <div onClick={handleExpansionClick} className={classes.iconContainer}>
        {icon}
      </div>
      <Typography onClick={e => { handleSelectionClick(e); }} component="div" className={classes.label}>
        <div style={{ display: 'flex', alignItems: 'center' }}>
          {label}
          {selected && nodeId.includes('mp4') &&
            <PauseIcon fontSize='small' sx={{ marginLeft: '10%' }} className='red-circle'
            />}
        </div>
      </Typography>
    </div>
  );
});

CustomContent.propTypes = {
  classes: PropTypes.object.isRequired,
  className: PropTypes.string,
  displayIcon: PropTypes.node,
  expansionIcon: PropTypes.node,
  icon: PropTypes.node,
  label: PropTypes.node,
  nodeId: PropTypes.string.isRequired,
  handleNodeToggle: PropTypes.func
};

function renderTreeItems(items, level) {
  level = level ? level : 0;

  if (!items) {
    return null;
  }

  const { label, children, color, id } = items;

  if (!children) {
    return (
      <TreeItem
        key={id}
        nodeId={id.toString()}
        label={label}
        level={level}
        sx={{ color: '#0000FF' }}
      />
    );
  }

  return (
    <TreeItem
      key={id}
      nodeId={id.toString()}
      label={label}
      ContentComponent={CustomContent}
      sx={{ color: color }}
    >
      {children.map((child) => renderTreeItems(child, level + 1))}
    </TreeItem>
  );
}

export default function MultiSelectTreeView(props) {
  return (
    <TreeView
      aria-label="rich object"
      defaultCollapseIcon={<ExpandMoreIcon />}
      defaultExpandIcon={<ChevronRightIcon />}
      expanded={props.expanded}
      selected={props.selected}
      onNodeToggle={props.onNodeToggle}
      onNodeSelect={props.onNodeSelect}
      sx={{ textAlign: 'left', mr: '5%' }}
    >
      {renderTreeItems(props.tree, null,)}
    </TreeView>
  );
}
