import React, { useEffect, useState } from 'react';
import '../App.css';
import logo from './logo.png';
import Requests from '../Requests';
import ApiClient from '../ApiClient';
import MuiAlert from '@mui/material/Alert';
import MoreVertIcon from '@mui/icons-material/MoreHoriz';
import AddIcon from '@mui/icons-material/Add';
import SessionNav from '../components/SessionNavigation';
import ConfirmationDialog from '../components/ConfirmationDialog';

import { createTheme, responsiveFontSizes, ThemeProvider } from '@mui/material/styles';

import { Box, Tab, Tabs, Typography, TextField, Button, List, ListItem, Dialog, DialogTitle, DialogContent, ListItemText, IconButton, ListItemButton } from '@mui/material';

import { FormControlLabel, RadioGroup, Radio } from '@mui/material';


import { Navigate } from 'react-router-dom';

import Navigation from '../components/Navigation';

let theme = createTheme();
theme = responsiveFontSizes(theme);

const Alert = React.forwardRef(function Alert(props, ref) {
  return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

const messages = {
  mismatch: 'Passwords Don\'t Match',
  success: 'Update Successful',
  failedLoc: 'Failed to add location. Refresh and try again.',
  failedAPI: 'Failed to retrieve data. Refresh and try again.',
  failedDelete: 'Failed to delete data. Refresh and try again.'
};

/**
 * Layout for the website that the homeowner will use to access their videos
 * @returns HomeOwner main page for the website
 */
const EditTags = () => {
  const [userInfo, setUserInfo] = useState(false);
  const [loaded, isLoaded] = React.useState(false);
  const [locationTypes, setLocationTypes] = useState([]);

  const [selectedTab, setSelectedTab] = useState(0);

  const [isModalOpen, setIsModalOpen] = useState(false);
  const [newLocationName, setNewLocationName] = useState('');

  const [severity, setSeverity] = React.useState('');
  const [displayAlert, setDisplayAlert] = React.useState(false);
  const [alertMessage, setAlertMessage] = React.useState(messages.success);

  const [isDialogOpen, setIsDialogOpen] = useState(false);
  const [selectedLocationId, setSelectedLocationId] = useState(1);
  const [selectedLocationName, setSelectedLocationName] = useState('');

  const [selectedRoom, setSelectedRoom] = useState('');
  const [newRoomName, setNewRoomName] = useState('');

  const [editLocName, setEditLocName] = useState('');

  const [selectedRoomTags, setSelectedRoomTags] = useState([]);

  const [roomTags, setRoomTags] = useState([]);

  const [tagVisible, setTagVisible] = useState(false);


  const [isEditTagOpen, setIsEditTagOpen] = useState(false);

  const [selectedTag, setSelectedTag] = useState('');
  const [newTagName, setNewTagName] = useState('');

  const [addTagToRoom, setAddTagToRoom] = useState(false);
  const [tagsToAdd, setTagsToAdd] = useState([]);

  const [addRoom, setAddRoom] = useState(false);

  const [addRoomName, setAddRoomName] = useState('');

  const [confirmCreateLoc, setConfirmCreateLoc] = useState(false);
  const [confirmDeleteLoc, setConfirmDeleteLoc] = useState(false);

  const [confirmEditTag, setConfirmEditTag] = useState(false);
  const [confirmDeleteTag, setConfirmDeleteTag] = useState(false);

  const [confirmCreateRoom, setConfirmCreateRoom] = useState(false);
  const [confirmEditRoom, setConfirmEditRoom] = useState(false);
  const [confirmDeleteRoom, setConfirmDeleteRoom] = useState(false);

  const [createNewTag, setCreateNewTag] = useState(false);
  const [createNewTagName, setCreateNewTagName] = useState('');
  const [confirmCreateNewTag, setConfirmCreateNewTag] = useState(false);

  const [radioVal, setRadioVal] = useState('Problem');





  useEffect(() => {
    fetch_locations();
    isLoaded(true);
    console.log(locationTypes);
  }, []);

  if (userInfo) {
    // Show a loading indicator or something else to indicate the page is not ready yet
    return <Navigate to='/menu' />;
  }

  const handleChange = (event, newValue) => {
    setSelectedTab(newValue);
  };

  const handleAddLocation = () => {
    setIsModalOpen(true);
  };

  /**
   * Fetches locations for each of the tags
   */
  const fetch_locations = async () => {
    const response = await ApiClient.get(Requests.getLocations).catch((error) => {
      console.log(error);
    });

    if (response.status === 200) {
      // console.log(response.data);

      const locations = response.data;
      const locationsWithRooms = await Promise.all(locations.map(async (location) => {
        const response2 = await ApiClient.get(Requests.getRoomByLocation, { location: location.location_name });
        if (response2.status === 200) {
          return { ...location, rooms: response2.data };
        }
      }));
      console.log(locationsWithRooms);
      setLocationTypes(locationsWithRooms);
    } else {
      setAlertMessage(messages.failedLoc);
      setSeverity('error');
      setDisplayAlert(true);
    }
  };
  /**
   * Creates new location in the database
   * @param {*} event onPress
   */
  const handleAddLocationSubmit = async (event) => {


    event.preventDefault();

    const response = await ApiClient.post(Requests.createLocation, { location_name: newLocationName });

    if (response.status === 200) {
      window.location.reload();
    } else {
      setAlertMessage(messages.failedAPI);
      setSeverity('error');
      setDisplayAlert(true);
    }
  };

  const handleDialogClose = () => {
    setIsDialogOpen(false);
    // setSelectedLocationId(null);
  };

  const handleLocationEdit = (event) => {
    console.log(event.target.value);
  };
  /**
   * Confirmation for the location updating
   */
  const confirmLocUpdate = async () => {
    const locationType = { location_id: selectedLocationId, location_name: editLocName };

    const response = await ApiClient.put(Requests.updateLocationById, locationType, { id: locationType.location_id }).catch((error) => {
      console.log(error);
      Promise.reject(error);
    });

    if (response.status === 200) {
      window.location.reload();
    } else {
      setAlertMessage(messages.failedDelete);
      setSeverity('error');
      setDisplayAlert(true);
    }
  };

  const confirmTagUpdate = async () => {

  };
  /**
   * Deletes location from the database
   */
  const handleLocationDelete = async () => {
    const response = await ApiClient.put(Requests.deleteLocation, {}, { id: selectedLocationId })
      .catch((error) => {
        console.log(error);
      });

    if (response.status === 200) {
      window.location.reload();
    } else {
      setAlertMessage(messages.failedDelete);
      setSeverity('error');
      setDisplayAlert(true);
    }
  };
  /**
   * Gets tags from the specific location that was clicked
   * @param {*} loc location
   * @param {*} idx index
   */
  const handleRoomClick = async (loc, idx) => {

    console.log(loc);
    console.log(loc.rooms[idx]);

    const response = await ApiClient.get(Requests.getExclusiveTags, { room_type: loc.rooms[idx].room_type }).catch((error) => {
      console.log(error);
    });

    if (response.status === 200) {
      console.log(response);
      setRoomTags(response.data);
      setSelectedRoom(loc.rooms[idx].room_type);
      console.log(loc.rooms[idx]);
      setTagVisible(true);
    } else {
      setAlertMessage(messages.failedAPI);
      setSeverity('error');
      setDisplayAlert(true);
    }

  };

  const handleEditLocOpen = (loc) => {
    setEditLocName(loc);
    setIsDialogOpen(true);
  };


  const RoomListItem = ({ room }) => {
    return (
      <ListItem>
        <ListItemText primary={`Room #${room.room_id}`} />
        <IconButton onClick={() => handleTagClick(room.room_id)}>
          <MoreVertIcon />
        </IconButton>
      </ListItem>
    );
  };
  /**
   * Update tag name in database
   */
  const changeTagName = async () => {
    const response = await ApiClient.put(Requests.updateTagName, {}, { new_name: newTagName, old_name: selectedTag }).catch((error) => {
      console.log(error);
    });

    if (response.status === 200) {
      setAlertMessage(messages.success);
      setSeverity('success');
      setDisplayAlert(true);
      window.location.reload();
    } else {
      setAlertMessage(messages.failedAPI);
      setSeverity('error');
      setDisplayAlert(true);
    }


  };

  const handleTagClick = async (roomId) => {
    const response = await ApiClient.get(`${Requests.getRoomTags}/${roomId}`).catch((error) => {
      console.log(error);
    });

    if (response.status === 200) {
      setSelectedRoomTags(response.data);
    }
  };
  /**
   * Update room name in database
   */
  const update_room_name = async () => {
    const new_info = { old_name: selectedRoom, new_name: newRoomName };

    const response = await ApiClient.put(Requests.updateRoomName, {}, new_info).catch((error) => {
      console.log(error);
    });


    if (response.status === 200) {
      setAlertMessage(messages.success);
      setSeverity('success');
      setDisplayAlert(true);
    } else {
      setAlertMessage(messages.failedAPI);
      setSeverity('error');
      setDisplayAlert(true);
    }
  };

  const handleAddTag = async () => {
    const response = await ApiClient.get(Requests.getNotIncludedTags, { room: selectedRoom }).catch((error) => {
      console.log(error);
    });

    if (response.status === 200) {
      console.log(response.data);
      setTagsToAdd(response.data);
      console.log(tagsToAdd);
      setAddTagToRoom(true);
    } else {
      setAlertMessage(messages.failedAPI);
      setSeverity('error');
      setDisplayAlert(true);
    }
  };

  /**
   * Adds a new default tag(non location exclusive) to the database
   * @param {*} tag Tag details
   */
  const addNewDefaultTag = async (tag) => {
    console.log(tag);
    console.log(selectedLocationId);
    console.log(selectedRoom);

    const new_tag = { location_id: tag.location_id, room_type: selectedRoom, type: tag.type, tag: tag.tag };

    const response = ApiClient.post(Requests.createNewDefault, new_tag).catch((error) => {
      console.log(error);
    });

    if (response.status === 200) {
      console.log('success');
      window.location.reload();
    } else {
      window.location.reload();
    }
  };

  const delete_default = async () => {
    const room = selectedRoom;
    const tag = selectedTag;

    const response = await ApiClient.put(Requests.deleteDefaultTag, {}, { room: room, tag: tag }).catch((error) => {
      console.log(error);
    });

    if (response.status === 200) {
      window.location.reload();
    } else {
      setAlertMessage(messages.failedAPI);
      setSeverity('error');
      setDisplayAlert(true);
    }

  };

  const create_new_room = async () => {
    const location_id = selectedLocationId;
    const room_type = addRoomName;

    const info = { location_id: location_id, room_type: room_type };

    const response = await ApiClient.post(Requests.createRoom, info).catch((error) => {
      console.log(error);
    });

    if (response.status === 200) {
      window.location.reload();
    } else {
      setAlertMessage(messages.failedAPI);
      setSeverity('error');
      setDisplayAlert(true);
    }
  };

  const delete_room = async () => {
    const info = { room: selectedRoom };
    const response = ApiClient.delete(Requests.deleteRoom, info).catch((error) => {
      console.log(error);
    });

    if (response.status === 200) {
      window.location.reload();
    }
    window.location.reload();
  };

  const handle_radio = (e, val) => {
    setRadioVal(val);
  };

  const create_new_tag = async () => {

    const response = await ApiClient.post(Requests.createNewTag, { type: radioVal, tag: createNewTagName });

    if (response.status === 200) {
      setCreateNewTag(false);
    } else {
      setAlertMessage(messages.failedAPI);
      setSeverity('error');
      setDisplayAlert(true);
    }

    window.location.reload();

  };


  return (<>
    <div style={{ position: 'absolute', top: 0, right: 0 }}>
      <img width='150' height='150' src={logo} alt="My Image" /><br></br>
    </div>
    <SessionNav /><br />
    {loaded &&
      <div style={{ height: 'auto' }} >
        <Navigation active='home' />
        <ThemeProvider theme={theme}>
          {displayAlert && <Alert severity={severity}> {alertMessage} </Alert>}
          <div>
            <Box sx={{ width: '100%' }}>
              <Tabs value={selectedTab} onChange={handleChange}>
                {locationTypes.map((item, index) => {
                  return <Tab key={item.location_id} label={
                    <Box sx={{ display: 'flex', alignItems: 'center' }} onClick={() => {
                      setSelectedLocationId(item.location_id);
                      console.log(selectedLocationId);
                    }}>
                      {item.location_name.replace('_', ' ')}
                      <MoreVertIcon sx={{ ml: 1 }}
                        onClick={() => {
                          setSelectedLocationId(item.location_id);
                          handleEditLocOpen(item.location_name);

                        }} />
                    </Box>
                  } value={index} />;
                })}
                <Tab label="+ Add Location" onClick={handleAddLocation} />
              </Tabs>
              <List>
                {locationTypes.map((item, index) => {
                  return (
                    <Box key={item.location_id} sx={{ p: 10 }} hidden={selectedTab !== index}>
                      {item.rooms && item.rooms.map((room, idx) => {
                        return (
                          <ListItem key={idx} sx={{ py: 1, flexDirection: 'row', width: '100%', alignContent: 'space-between' }}>
                            <Typography variant="h6" sx={{ color: 'darkblue' }}>
                              {room.room_type}
                            </Typography>
                            <MoreVertIcon onClick={() => {
                              handleRoomClick(item, idx);
                            }} />
                          </ListItem>

                        );
                      })}
                      <ListItem >
                        <AddIcon onClick={() => {
                          console.log('HERE NOW');
                          console.log(item.location_name);
                          console.log(selectedLocationId);
                          setSelectedLocationName(item.location_name);
                          setAddRoom(true);
                        }} />
                      </ListItem>
                    </Box>
                  );
                })}

              </List>
            </Box>

          </div>

          <Dialog open={tagVisible}>
            <DialogTitle>{selectedRoom}</DialogTitle>
            <DialogContent>
              <Typography variant="h6" sx={{ color: 'darkblue', m: 1 }}>
                Change Room Name (No Slashes &apos;/&apos;)
              </Typography>
              <TextField
                variant='standard'
                defaultValue={selectedRoom}
                label="Change Room Name"
                onChange={(event) => { setNewRoomName(event.target.value); }}
              />
              <Button type='button' variant='contained' sx={{ m: 3 }} onClick={() => { setConfirmEditRoom(true); }}>
                Change Room Name
              </Button>
              <Button type='button' variant='contained' sx={{ m: 3 }} onClick={() => { setConfirmDeleteRoom(true); }}>
                Delete Room
              </Button>
              <Typography variant="h6" sx={{ color: 'black', m: 1 }}>
                These tags appear first when videoing this room. All other tags are visible beneath or by search.
              </Typography>
              <List>
                {roomTags.map((tag, idx) => {
                  return (
                    <ListItem key={idx}>

                      <Typography variant="h6" sx={{ color: 'darkblue' }}>
                        {tag.tag}
                      </Typography>
                      <MoreVertIcon onClick={() => {
                        setSelectedTag(tag.tag);
                        setIsEditTagOpen(true);
                      }} />
                    </ListItem>
                  );
                })}
                <ListItem>
                  <AddIcon onClick={() => { handleAddTag(); }} />
                </ListItem>
              </List>
              <Button type='button' variant='contained' sx={{ m: 3 }} onClick={() => { setTagVisible(false); }}>
                Close
              </Button>
            </DialogContent>
          </Dialog>

          <ConfirmationDialog
            visible={confirmEditRoom}
            text={'This will edit this room only for this location'}
            header='Confirm Edit Room'
            onClose={() => { setConfirmEditRoom(false); }}
            onConfirm={update_room_name}
          />

          <ConfirmationDialog
            visible={confirmDeleteRoom}
            text={'Deleting this room will permenantly delete all associated tags that aren\'t shared with another room.'}
            header='Confirm Delete Room'
            onClose={() => { setConfirmDeleteRoom(false); }}
            onConfirm={delete_room}
          />

          <Dialog open={isModalOpen} onClose={() => setIsModalOpen(false)}>
            <DialogTitle>
              Create New Location
            </DialogTitle>
            <DialogContent>
              <Box sx={{ width: '100%', maxWidth: 360, bgcolor: 'background.paper', m: 3 }}>
                <Typography variant="h6" sx={{ mb: 2 }}>
                  Enter a name for the new location:
                </Typography>
                <form onSubmit={handleAddLocationSubmit}>
                  <TextField
                    label="Location Name"
                    variant="standard"
                    value={newLocationName}
                    onChange={(event) => setNewLocationName(event.target.value)}
                  />
                  <Box sx={{ mt: 2, display: 'flex', justifyContent: 'flex-end' }}>
                    <Button color="secondary" onClick={() => setIsModalOpen(false)}>
                      Cancel
                    </Button>
                    <Button color="primary" variant="outlined" sx={{ ml: 2 }} onClick={() => { setConfirmCreateLoc(true); }}>
                      Confirm
                    </Button>
                  </Box>
                </form>
              </Box>
            </DialogContent>
          </Dialog>

          <ConfirmationDialog
            visible={confirmCreateLoc}
            text={`Confirm if you want to create location ${newLocationName}`}
            header='Create New Location'
            onClose={() => { setConfirmCreateLoc(false); }}
            onConfirm={handleAddLocationSubmit}
          />




          <Dialog open={isDialogOpen} onClose={handleDialogClose}>
            <DialogTitle>{`Edit Location "${locationTypes.find((l) => l.location_id === selectedLocationId)?.location_name}"`}</DialogTitle>
            <DialogContent>
              <Box sx={{ width: '100%', maxWidth: 360, bgcolor: 'background.paper', m: 3 }}>
                <TextField
                  style={{ m: 3, p: 3 }}
                  label="New Location Name"
                  variant="standard"
                  value={editLocName}
                  onChange={(event) => {
                    setEditLocName(event.target.value);
                    handleLocationEdit(event);
                  }}
                />
                <Button variant="outlined" color='primary' onClick={() => { confirmLocUpdate(); }}>
                  Update Location Name
                </Button>
              </Box>
              <br />
              <br />

              <Button variant="outlined" color="error" onClick={() => { setConfirmDeleteLoc(true); }}>
                Delete Location
              </Button>
              <Box>
                <Button variant="outlined" color="primary" onClick={() => { setIsDialogOpen(false); }}>
                  Close
                </Button>
              </Box>

            </DialogContent>
          </Dialog>

          <ConfirmationDialog
            visible={confirmDeleteLoc}
            text={'Deleting this Location will delete the rooms associated with it, and they must be re-added'}
            header='Delete Location'
            onClose={() => { setConfirmDeleteLoc(false); }}
            onConfirm={handleLocationDelete}
          />

          <Dialog open={isEditTagOpen}>
            <DialogTitle>
              {`Edit Tag: ${selectedTag}`}
            </DialogTitle>
            <DialogContent>
              <Box sx={{ width: '100%', maxWidth: 360, bgcolor: 'background.paper', m: 3 }}>
                <TextField
                  label="Edit Tag Name"
                  variant="standard"
                  defaultValue={selectedTag}
                  onChange={(event) => {
                    setNewTagName(event.target.value);
                  }}
                />
                <Button variant="outlined" color='primary' onClick={() => { setConfirmEditTag(true); }}>
                  Update Tag Name
                </Button>
              </Box>
              <Button sx={{ m: 2 }} variant='outlined' color='primary' onClick={() => {
                setConfirmDeleteTag(true);
              }}>
                Delete As Default Tag
              </Button>

              <Button variant="outlined" color='primary' onClick={() => { setIsEditTagOpen(false); }}>
                Close
              </Button>
            </DialogContent>
          </Dialog>

          <ConfirmationDialog
            visible={confirmEditTag}
            text={'Editing this tag will change it globally, not just for this room'}
            header='Confirm Edit Tag'
            onClose={() => { setConfirmEditTag(false); }}
            onConfirm={changeTagName}
          />

          <ConfirmationDialog
            visible={confirmDeleteTag}
            text={`Deleting this tag will remove it from ${selectedRoom}, not globally.`}
            header='Delete Tag'
            onClose={() => { setConfirmDeleteTag(false); }}
            onConfirm={delete_default}
          />




          <Dialog open={addTagToRoom}>
            <DialogTitle>
              Add Default Tag to {selectedRoom}
            </DialogTitle>
            <DialogContent>

              <Box sx={{ mb: 5 }}>
                <Button variant='outlined' color='primary' onClick={() => { setCreateNewTag(true); }}>
                  Create New Tag
                </Button>
              </Box>
              <Typography variant='h5'>
                Existing Tags:
              </Typography>
              <Box sx={{ height: 400, overflow: 'hidden', overflowY: 'scroll' }}>
                <List>
                  {tagsToAdd.map((item, idx) => {
                    return (
                      <Box sx={{ width: '100%', maxWidth: 360, bgcolor: 'background.paper' }} key={idx}>
                        <ListItem component="div" onClick={() => {
                          addNewDefaultTag(item);
                        }}>
                          <ListItemButton divider>
                            <ListItemText sx={{ m: 0 }} color='selected' primary={`${item.tag}`} />
                          </ListItemButton>
                        </ListItem>
                      </Box>
                    );
                  })}
                </List>
              </Box>


              <Button variant="outlined" color='primary' onClick={() => { setAddTagToRoom(false); }}>
                Close
              </Button>
            </DialogContent>
          </Dialog>


          <Dialog open={addRoom}>
            <DialogTitle>
              Add Room To {selectedLocationName}
            </DialogTitle>
            <DialogContent>
              <Box sx={{ width: '100%', maxWidth: 360, bgcolor: 'background.paper', m: 3 }}>
                <TextField
                  label="New Room Name"
                  variant="standard"
                  onChange={(event) => {
                    setAddRoomName(event.target.value);
                  }}
                />
                <Button variant="outlined" color='primary' onClick={() => { setConfirmCreateRoom(true); }}>
                  Add new Room to {selectedLocationName}
                </Button>
              </Box>


              <Button variant="outlined" color='primary' onClick={() => { setAddRoom(false); }}>
                Close
              </Button>
            </DialogContent>
          </Dialog>

          <ConfirmationDialog
            visible={confirmCreateRoom}
            text={`This will add this room to ${selectedLocationName}`}
            header='Confirm Create Room'
            onClose={() => { setConfirmCreateRoom(false); }}
            onConfirm={create_new_room}
          />

          <Dialog open={createNewTag}>
            <DialogTitle>
              Create New Tag
            </DialogTitle>
            <DialogContent>
              <Box sx={{ width: '100%', maxWidth: 360, bgcolor: 'background.paper', m: 3 }}>
                <TextField
                  label="New Tag Name"
                  variant="standard"
                  onChange={(event) => {
                    setCreateNewTagName(event.target.value);

                  }}
                />
                <RadioGroup
                  row={true}
                  defaultValue={radioVal}
                  onChange={(e, value) => {
                    handle_radio(e, value);
                  }}
                >
                  <FormControlLabel value="Problem" control={<Radio />} label='Problem Tag' />
                  <FormControlLabel value="Feature" control={<Radio />} label='Feature Tag' />
                </RadioGroup>
              </Box>
              <Button variant="outlined" color='primary' onClick={() => { setConfirmCreateNewTag(true); }}>
                Create New Tag
              </Button>


              <Box sx={{ mt: 5 }}>
                <Button variant="outlined" color='primary' onClick={() => { setCreateNewTag(false); }}>
                  Close
                </Button>
              </Box>

            </DialogContent>
          </Dialog>

          <ConfirmationDialog
            visible={confirmCreateNewTag}
            text={'This will create a new tag. The tag will have to be added manually to any room necessary'}
            header='Confirm Create New Tag'
            onClose={() => { setConfirmCreateNewTag(false); }}
            onConfirm={create_new_tag}
          />




        </ThemeProvider>
      </div >
    }
  </>);
};

export default EditTags;
