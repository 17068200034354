import React, { useState, useEffect } from 'react';
import { useParams, Navigate } from 'react-router-dom';
import ApiClient from '../ApiClient';
import Requests from '../Requests';

import SubmitButton from '../components/SubmitButton';
import PasswordTextField from '../components/PasswordTextField';

import MuiAlert from '@mui/material/Alert';

const Alert = React.forwardRef(function Alert(props, ref) {
  return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

const ResetPassword = () => {
  const [password, setPassword] = useState('');
  const [confirm_password, setConfirmPassword] = useState('');

  const [error, setError] = React.useState(false);
  const [valid, setValid] = React.useState(false);

  const [apiCall, setApiCall] = React.useState(false);

  // Material UI Warning Messages
  const messages = {
    mismatch: 'Passwords Don\'t Match',
    success: 'Password Successfully Reset',
    weak: 'Weak Password',
    empty: 'Password Field is Empty',
    invalid_token: 'Login Token is Invalid'
  };

  // Material UI Alert Component Props
  const [severity, setSeverity] = React.useState('');
  const [displayAlert, setDisplayAlert] = React.useState(false);
  const [alertMessage, setAlertMessage] = React.useState(messages.success);

  // Disable Fields When Login Success
  const [loginSuccess, setLoginSuccess] = React.useState(false);

  // Users Unique Reset Token
  const { uuid } = useParams();

  const passwords_match = () => {
    return password == confirm_password;
  };

  const validate_uuid = async () => {
    if (valid) {
      setLoginSuccess(true);
      return;
    }

    try {
      setApiCall(true);

      const response = await ApiClient.get(
        Requests.validateUUID,
        { id: uuid }
      );

      setApiCall(false);

      // If a Message is Passed Back, an this Token has already been used
      if (!response.data.msg) {
        setValid(true);
      } else {
        setLoginSuccess(true); // Makes input fields unable to login

        setAlertMessage(messages.invalid_token);
        setSeverity('error');
        setDisplayAlert(true);
      }

    } catch (err) {
      console.log(err);
    }
  };

  /**
   *
   * @returns A boolean if the password meets the regex requirements
   */
  const is_secure = async () => {
    console.log(valid);
    if (loginSuccess) return;
    if (!valid) return;

    if (!passwords_match()) {
      setError(true);

      setAlertMessage(messages.mismatch);
      setSeverity('error');
      setDisplayAlert(true);

      return false;
    }

    const response = await ApiClient.post(
      Requests.isSecureLogin,
      { password: password },
    );

    if (response.status == 200) {
      if (!response.data.body) {
        setError(true);

        setAlertMessage(messages.weak);
        setSeverity('warning');
        setDisplayAlert(true);
      }

      if (response.data.body) {
        const generate_data = await ApiClient.post(
          Requests.createPassword,
          {
            password: password,
            uuid: uuid
          }
        );

        if (generate_data.data) {
          console.log('Successful Password Reset');

          setAlertMessage(messages.success);
          setSeverity('success');
          setDisplayAlert(true);
          setError(false);
          setLoginSuccess(true);

          window.location.href = '/';
        }
      }


    }
  };

  // if (loginSuccess) {
  //   return <Navigate to='/' />;

  // }

  // When script is mounted, validate UUID
  useEffect(() => {
    validate_uuid();
  }, []);

  const valid_chars = '[! @ # ^ * & $ % & ? + ( ) { } < > ` `   ~ | "]';

  return (
    <div className='login_body'>
      <div>
        {displayAlert && <Alert severity={severity}> {alertMessage} </Alert>}
        <form id='create' action='/login/create' method='post'>
          <h2>HomePhD - Create New Password</h2>
          <h6>Please create a new password according to the criteria below and click &apos;submit&apos;</h6>
          <ul>
            <li>6 Characters in Length</li>
            <li>1 Special Character:  {valid_chars}</li>
            <li>1 Number</li>
            <li>1 Upper & Lowercase Letter</li>
          </ul>
          <div className='field'>
            <PasswordTextField
              required
              disabled={loginSuccess}
              error={error}
              onChange={e => setPassword(e.target.value)}
              id="outlined-adornment-password"
              label="Password"
            /><br />
            <PasswordTextField
              required
              disabled={loginSuccess}
              error={error}
              onChange={e => setConfirmPassword(e.target.value)}
              id="outlined-adornment-confirm-password"
              label="Confirm Password"
            /><br />
          </div><br />
          <SubmitButton
            loading={apiCall}
            disabled={loginSuccess}
            onClick={() => is_secure()}
            label='submit'
          />
        </form>
      </div>
    </div>
  );
};

export default ResetPassword;
