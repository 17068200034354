// ./components/FormInput.js
import React, { } from 'react';

import Breadcrumbs from '@mui/material/Breadcrumbs';
import Link from '@mui/material/Link';

import PropTypes from 'prop-types';

Password.propTypes = {
  active: PropTypes.string.isRequired,
  style: PropTypes.object
};

/**
 * Navigation for users who are not logged in
 *
 * Currently composed of a Forgot Password button that redirects the user to
 * the forgot password page.
 *
 * @param {} props
 * @returns
 */

function Password(props) {
  return (
    <>
      <Breadcrumbs style={props.style} aria-label="breadcrumb" className='breadcrumb'>
        {/* <Link
          disabled={props.active == 'home' ? 'black' : 'inherit'}
          underline="hover"
          color={props.active == 'home' ? 'black' : 'inherit'}
          href="/">
          Home
        </Link> */}
        <Link
          color={props.active == 'forgot' ? 'black' : 'inherit'}
          underline="hover"
          href="/send_reset">
          Forgot Password
        </Link>
        {/* <Link
          color={props.active == 'login' ? 'black' : 'inherit'}
          underline="hover"
          href="/login">
          Login
        </Link> */}
      </Breadcrumbs>
    </>
  );
}
export default Password;
