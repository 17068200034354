import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import '../App.css';
import Requests from '../Requests';

import Table from '../components/Table';
import ApiClient from '../ApiClient';
import Utils from '../utils/Utils';
import DataLoading from '../components/DataLoading';

import Typography from '@mui/material/Typography';
import { createTheme, responsiveFontSizes, ThemeProvider } from '@mui/material/styles';

import SessionNav from '../components/SessionNavigation';

import logo from './logo.png';

const cols = ['Address 1', 'Address 2', 'City', 'State', 'Zip'];

function createHomeData(addr1, addr2, city, state, zip) {
  return { addr1, addr2, city, state, zip };
}

let theme = createTheme();
theme = responsiveFontSizes(theme);

/**
 * Layout for the website that the homeowner will use to access their videos
 * @returns HomeOwner main page for the website
 */
const MyHomes = () => {
  const [data, setData] = useState([]);
  const [userInfo, setUserInfo] = useState();
  const [searchData, setSearchData] = useState([]);
  const [sorted, setSorted] = useState(false);
  const [lastSorted, setLastSorted] = useState();

  const [homes, setHomes] = useState();

  const [state, setState] = useState();

  useEffect(() => {
    const fetch = async () => {
      const data = await Utils.fetchSessionData();

      setState(data.state);
      setUserInfo(data.userInfo);
    };

    fetch();
  }, []);

  const fetch_data = async () => {
    const res = await ApiClient.get(Requests.getHousesByUserId, { user_id: userInfo.user_id });

    if (res.status == 200) {
      const allHomes = [];
      res.data.map((home) => {
        const address_1 = {
          label: home.address_1 ? home.address_1 : '-',
          href: `/home/${home.house_id}`,
          index: data.length,
          type: 'edit',
        };

        allHomes.push(
          createHomeData(
            address_1,
            home.address_2,
            home.city,
            home.state,
            home.zip
          )
        );
      });

      setHomes(allHomes);
      setSearchData(allHomes);
    }
  };

  useEffect(() => {
    if (userInfo) {
      fetch_data();
    }
  }, [userInfo]);

  const onSortClick = (col) => {
    let localSorted = sorted;

    if (lastSorted != col) {
      localSorted = false;
      setSorted(false);
    }

    if (col == 'City')
      setSearchData(Utils.sortUserDataArray(homes, localSorted, 'city'));
    else if (col == 'State')
      setSearchData(Utils.sortUserDataArray(homes, localSorted, 'state'));
    else if (col == 'Zip')
      setSearchData(Utils.sortUserDataArray(homes, localSorted, 'zip'));

    setLastSorted(col);
    setSorted(!sorted);
  };

  return (<>
    <div style={{ zIndex: '-1', position: 'absolute', top: 0, right: 0 }}>
      <img width='150' height='150' src={logo} alt="My Image" /><br></br>
    </div >
    <SessionNav /><br />
    <div style={{ width: '100%' }} className="homeowner">
      <ThemeProvider theme={theme}>
        <Typography variant='h4'>My Homes</Typography>
        {homes &&
          <Table
            cols={cols}
            rows={searchData}
            onSortClick={onSortClick}
            pages
          />}
        {!homes &&
          <DataLoading dataFields={7} />
        }<br />
      </ThemeProvider>
    </div>
  </>);
};

export default MyHomes;
