class Tree { }

function filterBranch(tree, search, expanded) {
  let copy = { ...tree };
  let didExpand = false;

  if (tree && tree.children) {
    copy.children = tree.children.filter(child => (child.label.toLowerCase()).includes(search))
      .map(elem => {
        // Match on this level of tree
        expanded.push(elem.id);

        didExpand = true;
      });

    // Deeper recursion
    tree.children.map(child => {
      let filtered = filterBranch(child.children, search, expanded);

      let didExpandN = filtered.didExpand;
      copy.children = filtered.copy;

      if (didExpandN) {
        expanded.push(child.id);

        didExpand = true;
      }
    });
  } else {
    copy.children = Object.values(tree).filter(tag => (tag.label.toLowerCase()).includes(search))
      .map(t => {
        expanded.push(t.id);

        didExpand = true;
      });
  }

  return { didExpand, copy };
}

function findNodeByString(tree, searchStr) {
  const stack = [...tree.children];

  if (tree.id === searchStr) {
    return tree;
  }

  while (stack.length > 0) {
    const node = stack.pop();

    if (node.id === searchStr) {
      return node;
    }

    if (node.children) {
      stack.push(...node.children);
    }
  }

  return null;
}

Tree.filter = (tree, searchVal, expanded) => {
  if (!searchVal || !tree) {
    // If search value is empty, return the original tree
    return { filteredTree: tree, expanded };
  }

  let newExpanded = [...expanded];

  const filteredTree = { ...tree };

  // Highest level of Tree (Evaluations)
  if (filteredTree.children) {
    filteredTree.children.filter(evalu => (evalu.label.toLowerCase()).includes(searchVal.toLowerCase()))
      .map(elem => {
        newExpanded.push(elem.id);
      });

    filteredTree.children.map(elem => {
      const filtered = filterBranch(elem, searchVal.toLowerCase(), newExpanded);

      const didExpand = filtered.didExpand;

      if (didExpand) {
        newExpanded.push(elem.id);
      }
    });
  }

  return { filteredTree: filteredTree, expanded: newExpanded };
};

function expandNested(node) {

}

Tree.expandAll = (tree, node, defExpanded) => {
  const actualNode = findNodeByString(tree, node);

  let expanded = [...defExpanded];
  const stack = [...actualNode.children];

  if (!expanded.includes(actualNode.id)) {
    expanded.push(actualNode.id);
  }

  while (stack.length > 0) {
    const node = stack.pop();

    if (!expanded.includes(node.id)) {
      expanded.push(node.id);
    }

    if (node.children) {
      node.children.map(elem => {
        if (!expanded.includes(elem.id)) {
          expanded.push(elem.id);
        }
      });
    }

    if (node.children) {
      stack.push(...node.children);
    }
  }

  return expanded;
};

Tree.collapseAll = (tree, node, defExpanded) => {
  const actualNode = findNodeByString(tree, node);

  let expanded = [...defExpanded];

  if (expanded.includes(actualNode.id)) {
    expanded = expanded.filter(id => id !== actualNode.id);
  }

  actualNode.children.map(elem => {
    if (expanded.includes(elem.id)) {
      expanded = expanded.filter(id => id !== elem.id);
    }
  });

  return expanded;
};


export default Tree;
