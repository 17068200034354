class Utils { }

import ApiClient from '../ApiClient';
import Requests from '../Requests';

const MONTHS = [
  'January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December'
];

const ROLES = ['Homeowner', 'Evaluator', 'Boss Evaluator'];

Utils.format = (key, string) => {
  if (key === 'role') {
    return ROLES[parseInt(string) - 1];
  } else if (key === 'phone') {
    return Utils.formatPhoneNumber(string);
  }

  return string;
};

Utils.getHighestRole = (roles) => {
  let highestRole = 1;
  roles.map((role) => {
    if (role.role_id > highestRole) {
      highestRole = role.role_id;
    }
  });

  return highestRole;
};

Utils.filterDataArray = (data, search) => {
  if (!search) { return data; }

  const searchValue = search.replace(/\s/g, '').toLowerCase();

  const regex = new RegExp(searchValue.split('').join('.*'));

  const new_arr = data.filter((value) => {
    let allData = '';

    Object.values(value).forEach((data) => {
      if (typeof data !== 'object') {
        allData += data.toString().toLowerCase();
        allData = allData.replace(/\s/g, '');
      }
    });

    return regex.test(allData.toLowerCase());
  });

  return new_arr;
};


Utils.formatPhoneNumber = (number) => {
  const areaCode = number.slice(0, 3);
  const prefix = number.slice(3, 6);
  const lineNumber = number.slice(6, 10);

  return `(${areaCode}) ${prefix}-${lineNumber}`;
};

Utils.extractDateFromString = (str) => {
  const date = new Date(str);
  const year = date.getFullYear();
  const month = date.getMonth();
  const day = date.getDate();

  return `${MONTHS[month]} ${day}, ${year}`;
};

Utils.formatTimeData = (s) => {
  const minutes = Math.floor(s / 60);
  const seconds = Math.floor(s % 60);

  return `${minutes}:${seconds.toString().padStart(2, '0')})`;
};

Utils.fetchSessionData = () => {
  const remString = localStorage.getItem('remember');
  const remBool = remString === 'true' ? true : false;

  let tempState;

  tempState = !remBool ? sessionStorage : localStorage;

  var data = JSON.parse(tempState.getItem('userInfo'));

  if (data)
    data.highest_role = Utils.getHighestRole(data.roles);

  return {
    userInfo: data,
    state: tempState
  };
};

Utils.fetchUpdatedData = async (id) => {
  const response = await ApiClient.get(Requests.getUserById, { id: id });

  return response.status == 200 ? response.data : null;
};

Utils.sortUserDataArray = (data, asc, field) => {
  const newData = [...data];
  if (!asc)
    return newData.sort((a, b) => a[field].localeCompare(b[field], undefined, { numeric: true }));
  else
    return newData.sort((b, a) => a[field].localeCompare(b[field], undefined, { numeric: true }));
};

Utils.StateAbbreviations = ['AL', 'AK', 'AS', 'AZ', 'AR', 'CA', 'CO', 'CT', 'DE', 'DC', 'FM', 'FL', 'GA', 'GU', 'HI', 'ID', 'IL', 'IN', 'IA', 'KS', 'KY', 'LA', 'ME', 'MH', 'MD', 'MA', 'MI', 'MN', 'MS', 'MO', 'MT', 'NE', 'NV', 'NH', 'NJ', 'NM', 'NY', 'NC', 'ND', 'MP', 'OH', 'OK', 'OR', 'PW', 'PA', 'PR', 'RI', 'SC', 'SD', 'TN', 'TX', 'UT', 'VT', 'VI', 'VA', 'WA', 'WV', 'WI', 'WY'];
Utils.StateFull = ['Alabama', 'Alaska', 'American Samoa', 'Arizona', 'Arkansas', 'California', 'Colorado', 'Connecticut', 'Delaware', 'District of Columbia', 'Federated States of Micronesia', 'Florida', 'Georgia', 'Guam', 'Hawaii', 'Idaho', 'Illinois', 'Indiana', 'Iowa', 'Kansas', 'Kentucky', 'Louisiana', 'Maine', 'Marshall Islands', 'Maryland', 'Massachusetts', 'Michigan', 'Minnesota', 'Mississippi', 'Missouri', 'Montana', 'Nebraska', 'Nevada', 'New Hampshire', 'New Jersey', 'New Mexico', 'New York', 'North Carolina', 'North Dakota', 'Northern Mariana Islands', 'Ohio', 'Oklahoma', 'Oregon', 'Palau', 'Pennsylvania', 'Puerto Rico'];


export default Utils;
