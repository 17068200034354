import React, { useState, useEffect } from 'react';
import { Typography } from '@mui/material';
import { createTheme, responsiveFontSizes, ThemeProvider } from '@mui/material/styles';

import { Link } from 'react-router-dom';

import Button from '@mui/material/Button';

import TestRoutes from '../components/TestRoutes';

import logo from './logo.png';

import Utils from '../utils/Utils';
import SessionNav from '../components/SessionNavigation';

let theme = createTheme();
theme = responsiveFontSizes(theme);

/**
 * Layout for the website that the homeowner will use to access their videos
 * @returns HomeOwner main page for the website
 */
const HomeScreen = () => {
  const [userInfo, setUserInfo] = useState({});
  const [updatedUserInfo, setUpdatedUserInfo] = useState({});
  const [state, setState] = useState();
  const [role, setRole] = useState('');

  useEffect(() => {
    const fetchData = async () => {
      const data = await Utils.fetchSessionData();
      const updatedData = await Utils.fetchUpdatedData(data.userInfo.user_id);

      setState(data.state);
      setUserInfo(data.userInfo);
      setUpdatedUserInfo(updatedData);
      // setRole(userInfo.roles[0].role_name);

      if (userInfo.roles)
        map_roles(userInfo.roles[0].role_id);
    };

    fetchData();
  }, [state]);

  const map_roles = (role) => {

    if (role === 1) {
      setRole('Homeowner');
    } else if (role === 2) {
      setRole('Evaluator');
    } else if (role === 3) {
      setRole('Boss Evaluator');
    } else {
      setRole('Other');
    }


  };


  return (
    <div className='landing_page_body' style={{ marginBottom: '5%' }} >
      <SessionNav /><br />
      <ThemeProvider theme={theme}>

        <img width='200' height='200' src={logo} alt="My Image" /><br /><br />

        <Typography variant="h3"> Home Page</Typography><br />
        <div style={{ display: 'block', margin: '0 auto', textAlign: 'left', width: 'fit-content' }}>
          <Typography sx={{ color: 'black' }} variant="overline"> Name </Typography>
          <Typography variant="h4"> {updatedUserInfo.first_name} {updatedUserInfo.last_name} </Typography>
          <Typography sx={{ color: 'black' }} variant="overline"> Role </Typography>
          <Typography variant="h4">{role} </Typography>
        </div><br />

        {role == 'Boss Evaluator' && (
          <>
            <Link to={{ pathname: '/EvaluatorMainPage/homeowners' }}>
              <Button>
                See Homeowners
              </Button>
            </Link><br />

            <Link to={{ pathname: '/EvaluatorMainPage/eval' }}>
              <Button>
                See Evaluators
              </Button>
            </Link><br />


            <Link to="/MyHomes">
              <Button>
                View My Home Evaluations
              </Button>
            </Link><br />

            <Link to="/HomeOwner">
              <Button>
                See Evaluations
              </Button>
            </Link><br />
            <Link to="/EditTags">
              <Button>
                Edit Locations, Rooms, and Tags
              </Button>
            </Link><br />
          </>

        )}

        {role == 'Evaluator' && (
          <>
            <Link to={{ pathname: '/EvalMainPage/homeowners' }}>
              <Button>
                See Homeowners
              </Button>
            </Link><br />

            <Link to={{ pathname: '/EvalMainPage/eval' }}>
              <Button>
                See Evaluators
              </Button>
            </Link><br />


            <Link to="/MyHomes">
              <Button>
                My Assigned Homes
              </Button>
            </Link><br />

            <Link to="/HomeOwner">
              <Button>
                See Evaluations
              </Button>
            </Link><br />
          </>
        )}

        {role === 'Homeowner' && (
          <>
            <Link to="/MyHomes">
              <Button>
                Evaluations of My Homes
              </Button>
            </Link><br />
          </>
        )}

        {role === 'Other' && (
          <TestRoutes />
        )}



      </ThemeProvider>
    </div>
  );
};

export default HomeScreen;
