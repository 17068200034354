import React, { useEffect } from 'react';

import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemText from '@mui/material/ListItemText';
import TextField from '@mui/material/TextField';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import { FixedSizeList } from 'react-window';

import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';

import PropTypes from 'prop-types';

import '../styles/header.scss';
import { DialogTitle, ListItemAvatar, ListItemIcon } from '@mui/material';
import LinearProgress from '@mui/material/LinearProgress';

VirtualizedList.propTypes = {
  open: PropTypes.bool,
  data: PropTypes.array,
  onClick: PropTypes.func,
  selected: PropTypes.object,
  onCancel: PropTypes.func,
  onSearch: PropTypes.func,
  onLink: PropTypes.func,
  loading: PropTypes.bool,
};

function renderRow(props) {
  const { index, style, data } = props;

  const user = data.data[index];

  return (<>
    <ListItem
      style={style}
      key={index}
      component="div">
      <ListItemButton divider onClick={e => data.onClick(e, user)} selected={data.selected === user}>
        <ListItemText sx={{ m: 0 }} color='selected' primary={`${user.first_name} ${user.last_name}`} secondary={`${user.address_1}, ${user.city}, ${user.state}`} />
      </ListItemButton>
    </ListItem>
  </>);
}

/**
 * Will render a list of components on top of the current page as a modal.
 *
 * Props:
 * data [array] - All of the data elements to be included in list.
 * onClick [func] - What to do when a list element is clicked
 * selected [object] - Which data element was last clicked
 * onSearch [func] - Function callback to filter list
 * loading [bool] - Renders a loading bar when true.
 * open [bool] - Whether the modal is open
 * onCancel [func] - Function callback What to do when the modal is being canceled
 * onLink [func] - Function callback to determine what happens when modal form is submitted
 *
 * @param {*} props
 * @returns
 */

export default function VirtualizedList(props) {
  const windowHeight = window.innerHeight;
  const listHeight = windowHeight * 0.5; // set the list height to 70% of the window height

  return (<div>
    <Dialog open={props.open} scroll='body' sx={{ overflow: 'hidden' }}>
      <DialogTitle>
        Link Homeowner to Evaluator
      </DialogTitle>
      <ListItem>
        <TextField
          label="Search"
          variant="outlined"
          size='small'
          fullWidth
          sx={{ mb: 3, boxShadow: 1 }}
          onChange={props.onSearch}
        />
      </ListItem>
      <Box
        sx={{ width: '100%', height: '60%', maxWidth: 360, bgcolor: 'background.paper' }}
      >
        <FixedSizeList
          height={listHeight}
          width={360}
          itemSize={60}
          itemCount={props.data.length}
          overscanCount={5}
          itemData={{ data: props.data, onClick: props.onClick, selected: props.selected }}
        >
          {renderRow}
        </FixedSizeList>
      </Box>
      <DialogActions sx={{ m: '0 auto' }}>
        <Box sx={{ m: '0 auto' }}>
          <Button disabled={props.loading} onClick={props.onCancel}>{'Cancel'}</Button>
          <Button disabled={props.loading} onClick={props.onLink}>{'Link User'}</Button>
        </Box>
      </DialogActions>
      {props.loading && <Box sx={{ m: '0 auto', width: '100%' }}>
        <LinearProgress />
      </Box>
      }
    </Dialog>
  </div>);
}
