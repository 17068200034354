import React, { useState, useEffect, useRef } from 'react';
import { useParams } from 'react-router-dom';
import ApiClient from '../ApiClient';
import '../App.css';
import Requests from '../Requests';

import Utils from '../utils/Utils';
import Tree from '../utils/Tree';

import IconButton from '@mui/material/IconButton';
import PauseIcon from '@mui/icons-material/Pause';
import PlayArrowIcon from '@mui/icons-material/PlayArrow';

import Typography from '@mui/material/Typography';
import Paper from '@mui/material/Paper';
import Stack from '@mui/material/Stack';
import { styled } from '@mui/material/styles';
import { TextField } from '@mui/material';
import { Helmet } from 'react-helmet';
import Slider from '@mui/material/Slider';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';

import HolidayVillageIcon from '@mui/icons-material/HolidayVillage';
import InfoIcon from '@mui/icons-material/InfoOutlined';

import MuiAlert from '@mui/material/Alert';
import Tooltip from '@mui/material/Tooltip';
import TreeStructure from '../components/TreeStructure';
import SessionNavigation from '../components/SessionNavigation';
import Link from '@mui/material/Link';
import FormDialog from '../components/FormDialog';
import InfoList from '../components/InfoList';
import ConfirmationDialog from '../components/ConfirmationDialog';

import TagAlert from '@mui/material/Alert';
import AlertTitle from '@mui/material/AlertTitle';

import '../styles/videoweb.scss';

import { createTheme, responsiveFontSizes, ThemeProvider } from '@mui/material/styles';

let fields = [
  {
    data: [
      { header: 'Home Details' },
      { label: 'Address 1', id: 'address_1', locked: true },
      { label: 'Address 2', id: 'address_2', locked: true },
      { label: 'City', id: 'city', locked: true },
      { label: 'State', id: 'state', locked: true },
      { label: 'Zip', id: 'zip', locked: true },
    ]
  },
];


import logo from './logo.png';

const TinyText = styled(Typography)({
  fontSize: '0.75rem',
  opacity: 0.38,
  fontWeight: 500,
  letterSpacing: 0.2,
});

// Error Component Styling
const Alert = React.forwardRef(function Alert(props, ref) {
  return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

// Center the Alert Message
const alertCSS = {
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  mt: '20%',
};

let theme = createTheme();
theme = responsiveFontSizes(theme);

const Item = styled(Paper)(({ theme }) => ({
  backgroundColor: theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
  ...theme.typography.body2,
  padding: theme.spacing(1),
  textAlign: 'center',
  color: theme.palette.text.secondary,
}));

const VideoWeb = () => {
  const [time, setTime] = useState(0);
  const [locations, setLocations] = useState(0);
  const [treeItems, setTreeItems] = useState({ label: 'Empty', id: '-1' });
  const [expanded, setExpanded] = useState(['root']);
  const [defaultSelected, setDefaultSelected] = useState(['root']);
  const [noEntries, setNoEntries] = useState(false);
  const [videoMetaLoaded, setVideoMetaLoaded] = useState(false);
  const [reloadDuration, setReloadDuration] = useState([]);
  const [allMarks, setAllMarks] = useState([]);
  const [searchVal, setSearchVal] = useState();
  const [nodeSelected, setNodeSelected] = useState();
  const [detailsOpen, setDetailsOpen] = useState();
  const [houseInfo, setHouseInfo] = useState();

  const [confirmTag, setConfirmTag] = useState(false);
  const [selectedTag, setSelectedTag] = useState('');
  const [isPaused, setIsPaused] = useState(true);
  const [location, setCurrentLocation] = useState();
  const [tagsLoading, setTagsLoading] = useState(false);
  const [tagAdded, setTagAddedSuccess] = useState(false);
  const [hovering, setHovering] = useState(false);
  const [hoverTime, setHoverTime] = useState(0);


  const vidRef = useRef();
  const copyRef = useRef();
  const canvasBoxRef = useRef();
  const canvasRef = useRef();

  const [tags, setCurrTags] = useState([]);
  const [filteredTags, setFilteredTags] = useState([]);
  const [currVid, setCurrVideo] = useState('loading');

  const [position, setPosition] = useState(0);
  const [paused, setPaused] = useState(true);

  const [allVideos, setAllVideos] = useState([]);
  const [notes, setNotes] = useState('');
  const [noteID, setNoteID] = useState(0);

  const [confirmUpdate, setConfirmUpdate] = useState(false);
  const [noteValue, setNoteValue] = useState('');

  const { house_id, defaultVid } = useParams();
  const [failedUpdate, setFailedUpdate] = useState(false);


  /**
 * Handles dynamic array filtering
 * @param {*} e contains text string value
 */

  const onSearchFilter = (e) => {
    setFilteredTags(Utils.filterDataArray(tags, e.target.value));
  };

  const handleNodeToggle = (_, node_ids) => {
    let newExpanded = [...node_ids];

    for (let i = 0; i < newExpanded.length; i++) {
      const id = newExpanded[i];

      if (currVid === id) {
        break;
      } else if (id.includes('mp4')) {
        setCurrVideo(id);
        vidRef.current.pause();
        setPosition(0);
        setTime(0);

        setDefaultSelected(['root', id]);

        break;
      }
    }

    setExpanded(newExpanded);
  };

  useEffect(() => {
    if (allVideos[currVid]) {
      let newMarks = [];
      allVideos[currVid].map((tag) => {
        newMarks.push({
          value: tag.timestamp,
          label: <Tooltip placement={'left-end'} arrow title={tag.tag_name} enterDelay={200} leaveDelay={200}>
            <Box onClick={() => { vidRef.current.pause(); }} sx={{ width: 7, height: 30, backgroundColor: 'primary.dark', }}></Box>
          </Tooltip>,
        });
      });
      setAllMarks(newMarks);
    }
  }, [currVid]);

  function formatDuration(value) {
    const minute = Math.floor(value / 60);
    const secondLeft = value - minute * 60;
    return `${minute}:${secondLeft < 10 ? `0${secondLeft}` : secondLeft}`;
  }

  function buildTree(evaluations) {
    let tempVideos = [];


    let c = 0; // counter for generating unique ids
    const tree = {
      label: 'Evaluations',
      id: 'root',
      color: 'grey',
      children: []
    };

    let firstEval = false;

    // Iterate Evaluations
    for (let e in evaluations) {
      const evaluation = evaluations[e];

      if (typeof evaluation == 'object') {
        if (!evaluation.eval_date) { break; }

        evaluation.id = `Eval_${c++}`;
        const evaluationNode = {
          label: Utils.extractDateFromString(evaluation.eval_date),
          id: evaluation.id,
          color: '#434343',
          children: []
        };

        if (!firstEval) {
          setNodeSelected(evaluation.id);
          setExpanded(['root', evaluation.id]);
          setDefaultSelected([evaluation.id]);

          firstEval = true;
        }

        // Iterate Videos
        for (let v in evaluation.videos) {
          const video = evaluation.videos[v];

          if (typeof video == 'object') {
            video.id = `${evaluation.id}_${c++}`;
            const videoNode = {
              label: video.location,
              id: video.video_name,
              color: 'black',
              children: []
            };

            console.log(v, evaluation.videos.length);
            if (e == 0 && v == 0) {
              setCurrentLocation(video.location);
              setCurrVideo(video.video_name);
              setPaused(true);

              const new_selected = [...defaultSelected];
              new_selected.push(video.video_name);
              setDefaultSelected(new_selected);

              getDefaultNotes(video.video_name);


            }

            // Iterate Tags
            for (let t in video.tags) {
              const tag = video.tags[t];

              if (typeof tag == 'object') {
                tag.id = c++;
                const tagNode = {
                  label: tag.tag_name + ' (' + Utils.formatTimeData(tag.timestamp),
                  id: tag.id + '-Time_' + tag.timestamp,
                };
                videoNode.children.push(tagNode);

                if (!tempVideos[video.video_name])
                  tempVideos[video.video_name] = [];

                tempVideos[video.video_name].push(tag);
              }
            }

            if (videoNode.children.length == 0) {
              const tagNode = {
                label: 'No tags ',
                id: ''
              };
              videoNode.children.push(tagNode);
            }

            evaluationNode.children.push(videoNode);
          }
        }
        tree.children.push(evaluationNode);
      }
    }

    setAllVideos(tempVideos);

    if (tree.children.length == 0) {
      setNoEntries(true);
    }

    return tree;
  }

  const fetch_videos = async () => {
    const res = await ApiClient.get(Requests.getVideoByHouseId, { house_id: house_id });

    const evaluations = res.data;

    const treeItems = buildTree(evaluations);

    console.log(treeItems);

    setTreeItems(treeItems);
    setLocations(locations);
  };

  const fetch_tags = async () => {
    console.log(currVid);
    const res = await ApiClient.get(Requests.getAllAvailableTags, { room_type: 'Kitchen' });

    if (res.status == 200) {
      setCurrTags(res.data);
      setFilteredTags(res.data);
    }
  };


  const handleMouseMove = (event) => {
    const sliderRect = event.currentTarget.getBoundingClientRect();
    const mouseX = event.clientX - sliderRect.left;
    const positionRatio = mouseX / sliderRect.width;
    const value = Math.round(positionRatio * vidRef.current.duration);

    setHoverTime(value);

    if (canvasRef.current) {
      const ctx = canvasRef.current.getContext('2d');

      setHovering(true);

      const sliderRect = event.currentTarget.getBoundingClientRect();
      const mousePosition = event.clientX - sliderRect.left;
      const sliderWidth = sliderRect.width;

      // Calculate the percentage of the slider that the mouse is currently over
      const percentage = mousePosition / sliderWidth;

      // Calculate the new position of the canvas based on the percentage
      const newPosition = percentage * sliderWidth - canvasRef.current.width / 2;
      canvasBoxRef.current.style.transform = `translateX(${newPosition}px)`;

      if (!copyRef.current) {
        copyRef.current = document.createElement('video');
        copyRef.current.src = `https://homephdvideos.s3.amazonaws.com/${currVid}`;
      }

      if (copyRef.current.src !== `https://homephdvideos.s3.amazonaws.com/${currVid}`) {
        copyRef.current.src = `https://homephdvideos.s3.amazonaws.com/${currVid}`;
      }

      copyRef.current.currentTime = value;


      ctx.drawImage(copyRef.current, 0, 0, canvasRef.current.width, canvasRef.current.height);
    }
  };

  const fetch_home = async () => {
    const res = await ApiClient.get(Requests.getHousesById, { id: house_id });

    if (res.status == 200) {
      const house_info = res.data;

      setHouseInfo(house_info);
    }
  };

  const onAddTagSubmit = async () => {
    const tags = { tag: selectedTag.tag, name: selectedTag.tag_id, video_name: currVid, time: vidRef.current.currentTime };

    const res = await ApiClient.post(Requests.addTagToVideo, tags);

    if (res.status == 200) {
      allMarks.push({
        value: vidRef.current.currentTime,
        label: <Tooltip placement={'left-end'} arrow title={selectedTag.tag} enterDelay={200} leaveDelay={200}>
          <Box sx={{ width: 7, height: 30, backgroundColor: 'primary.dark', }}></Box>
        </Tooltip>,
      });

      if (!isPaused) {
        vidRef.current.play();
      }

      setTagAddedSuccess(true);
      setAllMarks(allMarks);
      setConfirmTag(false);
    }
  };

  useEffect(() => {
    fetch_videos();
    fetch_home();
    fetch_tags();
  }, []);

  useEffect(() => {
    if (houseInfo) {
      const address_1 = {
        label: 'Address 1',
        default: houseInfo.address_1,
        id: 'home1',
        display: 'label',
      };

      const address_2 = {
        label: 'Address 2',
        default: houseInfo.address_2,
        id: 'home2',
        display: 'label',
      };

      const city = {
        label: 'City',
        display: 'label',
        default: houseInfo.city,
        id: 'city',
      };

      const state = {
        label: 'State',
        entries: Utils.StateAbbreviations,
        default: houseInfo.state,
        id: 'state',
        display: 'label',
      };

      const zip = {
        label: 'Zip',
        default: houseInfo.zip,
        id: 'zip',
        locked: true,
        display: 'label',
      };

      const newHouseFields = [
        {
          data: [address_1, address_2, city, state, zip]
        }
      ];

      fields = newHouseFields;
    }
  }, [houseInfo]);

  useEffect(() => {
    console.log(currVid);
  }, [currVid]);


  const valueLabelFormat = (value) => {
    return value === 0 ? (
      <Tooltip title="This is the default value">
        <span>{value}</span>
      </Tooltip>
    ) : (
      value
    );
  };

  const onTagClick = (_, tag) => {
    setSelectedTag(tag);
    setConfirmTag(true);
    setIsPaused(vidRef.current.paused);

    vidRef.current.pause();
  };

  const onNodeSelect = async (_, t) => {
    setNodeSelected(t);
    setNotes(' ');
    setNoteID(0);

    if (t.includes('mp4')) {
      setDefaultSelected([t]);
      setCurrVideo(t);
      setPaused(true);

      setTagsLoading(true);
      const res = await ApiClient.get(Requests.getTagsByVideoName, { video_name: t });
      if (res.status == 200) {
        setCurrTags(res.data);
        setFilteredTags(res.data);
        setTagsLoading(false);
      }

      const response = await ApiClient.get(Requests.getNotesByVideo, { video_id: t });

      if (response.status === 200) {
        console.log(response.data);
        console.log(response.data.length);
        if (response.data.length !== 0) {
          setNotes(response.data[0].note);
          setNoteID(response.data[0].note_id);
        } else {
          setNotes('No Notes Added');
          setNoteID();
        }

      }


    }

    if (t.includes('Time')) {
      let time = parseInt(t.split('_')[1]);
      vidRef.current.currentTime = time;
      // setTime(parseInt(t.split('_')[1]));
    }
  };

  const getDefaultNotes = async (video_name) => {
    const response = await ApiClient.get(Requests.getNotesByVideo, { video_id: video_name });

    if (response.status === 200) {
      console.log(response.data);
      console.log(response.data.length);
      if (response.data.length !== 0) {
        setNotes(response.data[0].note);
        setNoteID(response.data[0].note_id);
      } else {
        setNotes('No Notes Added');
        setNoteID();
      }

    }
  };

  const onExpandAll = (e) => {
    const newExpanded = Tree.expandAll(treeItems, nodeSelected, expanded);

    setExpanded(newExpanded);
  };

  const onCollapseAll = (e) => {
    const newExpanded = Tree.collapseAll(treeItems, nodeSelected, expanded);

    setExpanded(newExpanded);
  };

  const onNodesSearch = (e) => {
    const val = e.target.value;

    let newExpanded = ['root'];

    const newTree = Tree.filter(treeItems, val, newExpanded);

    setExpanded(newTree.expanded);
    setSearchVal(val);
  };

  const update_notes = async () => {
    const info = { note: notes, note_id: noteID };

    const response = await ApiClient.post(Requests.updateVideoNotes, info);

    if (response.status === 200) {
      window.location.reload();
    } else {
      setFailedUpdate(true);
    }
  };

  return (<>
    <SessionNavigation />
    <Helmet>
      <meta name="viewport" content="width=device-height, initial-scale=1.0"></meta>
    </Helmet>
    <div style={{ height: '90vh', marginTop: '1vh' }}>
      <div style={{ position: 'absolute', top: 0, right: 0 }}>
        <img width='150' height='150' src={logo} alt="My Image" /><br></br>
      </div>
      <IconButton
        sx={{ position: 'absolute', top: '2%', left: '3%' }}
        color='info'
        disableRipple
        size='large'
        onClick={() => { window.location.href = '/MyHomes'; }}>
        <Typography variant='subtitle1'>
          <HolidayVillageIcon sx={{ fontSize: '300%' }} /><br />
          Assigned Homes
        </Typography>
      </IconButton>
      <ThemeProvider theme={theme}>
        {houseInfo &&
          <>
            <Typography variant='h4' sx={{ display: 'flex', justifyContent: 'center' }}>
              Home Evaluation - {houseInfo.address_1}
              <IconButton
                disableRipple
                color='info'
                size='medium'
                onClick={() => { setDetailsOpen(true); }}>
                <Typography sx={{ display: 'block' }} variant='subtitle1'>
                  <InfoIcon size='large' sx={{ fontSize: '3vh' }} /><br />
                </Typography>
              </IconButton>
            </Typography>
          </>
        }
        <Stack direction="row" justifyContent="center" spacing={3} sx={{ m: 0, height: '54vh' }}>
          {noEntries && (
            <Alert sx={alertCSS} severity={'error'}>
              {'No Evaluations Have been Recorded'}
            </Alert>
          )}
          {failedUpdate && (
            <Alert sx={alertCSS} severity={'error'}>
              {'Error updating notes for this video, please refresh the page and try again.'}
            </Alert>
          )}
          {!noEntries &&
            <>
              <Item sx={{ height: '60vh', overflowY: 'scroll', width: '22%', boxShadow: 'none' }}>
                <TextField
                  label="Search"
                  variant="outlined"
                  fullWidth
                  onChange={onNodesSearch}
                  sx={{ mb: '2%', display: 'flex', boxShadow: 1, width: '100%' }}
                />
                <Box sx={{ m: 0 }}>
                  <Button onClick={onExpandAll}>
                    {'Expand All'}
                  </Button>
                  <Button onClick={onCollapseAll}>
                    {'Collapse All'}
                  </Button>
                </Box>
                <TreeStructure
                  tree={treeItems}
                  expanded={expanded}
                  selected={defaultSelected}
                  onNodeToggle={handleNodeToggle}
                  searchVal={searchVal}
                  onNodeSelect={(e, t) => onNodeSelect(e, t)}
                />
              </Item>
              <Item sx={{ width: '18%', height: '60vh' }}>
                <video
                  key={currVid}
                  id="playerRef"
                  playsInline
                  width='100%'
                  height='100%'
                  style={{ border: 0 }}
                  onTimeUpdate={e => { setTime(e.target.currentTime); }}
                  onPause={() => setPaused(true)}
                  onPlay={() => setPaused(false)}
                  controls
                  ref={vidRef}
                  onLoadedMetadata={() => { setVideoMetaLoaded(true); setReloadDuration(!reloadDuration); setTime(0); }}
                >
                  <source
                    src={`https://homephdvideos.s3.amazonaws.com/${currVid}`}
                    type="video/mp4"
                  />
                </video>
              </Item>
              <Item sx={{ width: '20%', height: '115%', boxShadow: 'none' }}>
                {tagAdded &&
                  <TagAlert sx={{ mb: '5%' }} severity="success">
                    <AlertTitle>Tag Successfully Added to Video!</AlertTitle>
                  </TagAlert>
                }

                <InfoList
                  loading={tagsLoading}
                  onSearch={onSearchFilter}
                  onClick={onTagClick}
                  data={filteredTags}
                />
              </Item>
            </>
          }
        </Stack>
        {videoMetaLoaded &&
          <>
            <Box
              sx={{ width: '90%', m: '5%', mt: '9%' }}>
              <IconButton sx={{
                width: '100%', alignItems: 'center'
              }} disableRipple onClick={() => {
                setPaused(!paused);
                if (paused)
                  vidRef.current.play();
                else
                  vidRef.current.pause();
              }}>
                {!paused ? (
                  <PauseIcon />
                ) : (
                  <PlayArrowIcon />
                )
                }
              </IconButton>

              {noteID && (
                <>
                  <Typography>
                    Video Notes:
                  </Typography>
                  <TextField sx={{ width: '80%' }} defaultValue={`${notes}`} onChange={(event) => { setNotes(event.target.value); }} />
                  <Button onClick={() => { setConfirmUpdate(true); }}>
                    Update Notes
                  </Button>
                </>
              )}

              <Box
                ref={canvasBoxRef}
                sx={{
                  position: 'relative',
                  mb: '1%',
                  left: `${(position / Math.round(vidRef.current.duration)) * 100}%`,
                  pointerEvents: 'none',
                  width: '10%',
                  color: 'blue',
                }}
                color={'primary'}
              >
                <canvas
                  id="canvas"
                  width={hovering ? 70 : 0}
                  height={hovering ? 100 : 0}
                  ref={canvasRef}
                  style={{
                    position: 'absolute',
                    border: hovering ? '1px solid blue' : '0px solid black',
                    bottom: '50%',
                    transition: 'opacity 0.3s ease-in-out',
                  }}
                ></canvas>
                {hovering &&
                  <Typography
                    variant='subtitle2'
                    sx={{ position: 'absolute', paddingTop: '3%' }}
                  >{formatDuration((Math.round(hoverTime)))}
                  </Typography>
                }
              </Box>
              <Slider
                aria-label="time-indicator"
                height='5vh'
                value={time}
                key={reloadDuration}
                valueLabelFormat={valueLabelFormat}
                onMouseMove={handleMouseMove}
                onMouseLeave={() => {
                  setHovering(false);

                  if (canvasRef) {
                    const ctx = canvasRef.current.getContext('2d');
                    ctx.clearRect(0, 0, canvasRef.current.width, canvasRef.current.height);
                  }
                }}
                min={0}
                step={.000001}
                max={vidRef.current.duration ? Math.round(vidRef.current.duration) : 0}
                onChange={(_, value) => { vidRef.current.currentTime = value; setTime(value); }}
                marks={allMarks}
                sx={{
                  height: 4,
                  '& .MuiSlider-thumb': {
                    width: 8,
                    height: 8,
                    '&:before': {
                      boxShadow: '0 2px 12px 0 rgba(0,0,0,0.4)',
                    },
                    '&:hover, &.Mui-focusVisible': {
                      boxShadow: `0px 0px 0px 8px ${theme.palette.mode === 'dark'
                        ? 'rgb(255 255 255 / 16%)'
                        : 'rgb(0 0 0 / 16%)'}`,
                    },
                    '&.Mui-active': {
                      width: 20,
                      height: 20,
                    },
                  },
                  '& .MuiSlider-rail': {
                    opacity: 0.28,
                  },
                }}
              />
              <br />

              <Box
                sx={{
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'space-between',
                  mt: -2,
                }}
              >
                <TinyText>{formatDuration(Math.round(vidRef.current.currentTime))}</TinyText>
                <TinyText>-{formatDuration((Math.round(vidRef.current.duration - vidRef.current.currentTime)))}</TinyText>
              </Box>
            </Box>
            <FormDialog
              title={'Home Details'}
              open={detailsOpen}
              onClose={() => setDetailsOpen(false)}
              noConfirmButton={true}
              closeText={'Close'}
              fields={fields}
            ></FormDialog>

            <ConfirmationDialog
              header={'Confirm Update Notes'}
              text={'Are you sure you would like to modify the notes for this video?'}
              visible={confirmUpdate}
              onClose={() => { setConfirmUpdate(false); }}
              onConfirm={update_notes}
            />

            <ConfirmationDialog
              header={'Add Tag'}
              text={`Would you like to add the tag "${selectedTag.tag}" at ${formatDuration(Math.round(vidRef.current.currentTime))}`}
              confirmText={'Add'}
              visible={confirmTag}
              onClose={() => {
                setConfirmTag(false);

                if (!isPaused) {
                  vidRef.current.play();
                }
              }}

              onConfirm={onAddTagSubmit}
            />
          </>
        }
      </ThemeProvider >
    </div >
  </>);

};

export default VideoWeb;
