// ./components/FormInput.js
import React, { } from 'react';

import PropTypes from 'prop-types';

import InputLabel from '@mui/material/InputLabel';
import OutlinedInput from '@mui/material/OutlinedInput';
import FormControl from '@mui/material/FormControl';
import IconButton from '@mui/material/IconButton';
import InputAdornment from '@mui/material/InputAdornment';
import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';

PasswordTextField.propTypes = {
  required: PropTypes.bool,
  onChange: PropTypes.func,
  error: PropTypes.bool,
  id: PropTypes.string,
  label: PropTypes.string,
  disabled: PropTypes.bool
};

/**
 * Hide password field
 *
 * Props:
 * required [bool] - If the field needs to be filled out
 * onChange [func] - Function callback whenever the field is changed
 * error [bool] - A red outline appears around the textfield
 * id [string] - Unique id for the textfield
 * label [string] - Text to be displayed
 * disabled [bool] - Input is not accepted when true
 *
 * @param {*} props
 * @returns
 */
function PasswordTextField(props) {
  const [showPassword, setShowPassword] = React.useState(false);

  const handleClickShowPassword = () => setShowPassword((show) => !show);

  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };
  return (
    <>
      <FormControl sx={{ m: 1, width: '25ch' }} variant="outlined">
        <InputLabel htmlFor="outlined-adornment-password">{props.label}</InputLabel>
        <OutlinedInput
          required={props.required}
          id={props.id}
          onChange={props.onChange}
          label={props.label}
          error={props.error}
          type={showPassword ? 'text' : 'password'}
          disabled={props.disabled}
          endAdornment={
            <InputAdornment position="end">
              <IconButton
                aria-label="toggle password visibility"
                onClick={handleClickShowPassword}
                onMouseDown={handleMouseDownPassword}
                edge="end"
              >
                {showPassword ? <Visibility /> : <VisibilityOff />}
              </IconButton>
            </InputAdornment>
          }
        />
      </FormControl>
    </>
  );
}
export default PasswordTextField;
