const requests = {
  isSecureLogin: '/login',
  createPassword: '/login/create',
  validateUUID: '/reset/:id',
  validateLogin: '/login/verify',
  sendReset: '/login/forgot',
  logout: '/login/logout',
  getHouses: '/house/user/:id',
  getUsersByRole: '/users/role/:role_id',
  getUserById: '/users/:id',
  createUser: '/users', // .post will create, .get will get all users
  deleteUser: '/users/delete_user/:user_id',
  updateUser: '/users/update_user',
  updateUserHomeInfo: '/users/update_user_home_info',
  addUserHomeInfo: '/users/add_user_home_info',
  getHousesById: '/houses/:id',
  getHousesByUserId: '/houses/get_by_user/:user_id',
  getAllHouses: '/houses',
  createHouse: '/houses',
  getAllHomeVideo: '/user_house/get_homes_videos',
  getAllTags: '/tags/get/get_tags_by_id/:video_id',
  getVideoByHouseId: '/home/get_video_by_house_id/:house_id',
  getHomeEvals: '/user_house/get_evals',
  getHousesByRole: '/user_house/getby/roles/:role_id',
  deleteUserHouse: '/users_house/delete/:user_id/:house_id',
  linkEvaluatorToHome: '/user_house/link_evaluator_to_home',
  getAllAvailableTags: '/tags/:room_type',
  addTagToVideo: '/tags/add_tag_to_video',
  getTagsByVideoName: '/video/get_tags_by_video_name/:video_name',
  createNonEval: '/houses/create_non_eval',
  getLocations: '/locations',
  createLocation: '/locations',
  getRoomByLocation: '/tags/get/rooms/:location',
  deleteLocation: '/locations/delete/:id',
  updateLocationById: '/locations/:id',
  getExclusiveTags: '/tags/get_exclusive_tags/:room_type',
  updateTagName: '/tags/update_name/:new_name/:old_name',
  updateRoomName: '/tags/update_name/room/:new_name/:old_name',
  getNotIncludedTags: '/tags/get_not_included/:room',
  createNewDefault: '/tags/create_new_default/tag/for/room',
  deleteDefaultTag: '/tags/delete/default/room/:room/:tag',
  createRoom: '/tags/create/new/room',
  deleteRoom: '/tags/delete/room/:room',
  getNotesByVideo: '/video/get/notes/by/video/id/:video_id',
  updateVideoNotes: '/video/update/notes/on/web',
  createNewTag: '/tags'
};

export default requests;
