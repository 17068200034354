import React, { useEffect, useState } from 'react';
import '../App.css';
import Typography from '@mui/material/Typography';
import Requests from '../Requests';
import DataLoading from '../components/DataLoading';

import Table from '../components/Table';
import ApiClient from '../ApiClient';

import { createTheme, responsiveFontSizes, ThemeProvider } from '@mui/material/styles';

import SessionNav from '../components/SessionNavigation';
import Utils from '../utils/Utils';

import logo from './logo.png';

const cols = ['Email', 'First Name', 'Last Name', 'Phone'];
const new_cols = ['Address 1', 'Address 2', 'City', 'State', 'Zip'];

const homes_cols = ['Address One', 'Address Two', 'City', 'State', 'Zip'];
const eval_cols = ['Evaluation Date', 'Homeowner Name', 'Evaluator Name'];

let theme = createTheme();
theme = responsiveFontSizes(theme);

/**
 * Layout for the website that the homeowner will use to access their videos
 * @returns HomeOwner main page for the website
 */

function createData(email, f_name, l_name, phone, collapses) {
  return { email, f_name, l_name, phone, collapses };
}

function createFullData(addr1, addr2, city, state, zip, collapses) {
  return { addr1, addr2, city, state, zip, collapses };
}

function createHomeData(addr1, addr2, city, state, zip) {
  return { addr1, addr2, city, state, zip };
}

function createEvalData(eval_date, eval_name, homeowner_name) {
  return { eval_date, eval_name, homeowner_name };
}

const HomeOwner = () => {
  const [loaded, setLoaded] = useState(false);
  const [data, setData] = useState([]);
  const [api, setApiCall] = useState(false);
  const [search, setSearchPage] = useState(false);
  const [searchData, setSearchData] = useState([]);
  const [userData, setUserData] = useState([]);
  const [dataLoaded, setDataLoaded] = useState(false);

  const [sorted, setSorted] = useState(false);
  const [lastSorted, setLastSorted] = useState(false);

  const fetch_homes = async () => {
    const response = await ApiClient.get(Requests.getHomeEvals);

    if (response.status === 200) {
      const data = response.data.user_evals;
      const data_objects = [];

      data.map((user, idx1) => {

        const evaluations = user.evaluation_info;
        let eval_data = [];
        evaluations.reverse();
        evaluations.map((evals, idx2) => {
          const eval_date = {
            label: `Evaluation - ${Utils.extractDateFromString(evals.eval_date)}`,
            href: `/home/${user.house_id}/${idx2}`,
            index: idx2,
            type: 'edit',
          };

          eval_data.push(createEvalData(eval_date, `${evals.homeowner_first} ${evals.homeowner_last}`, `${evals.evaluator_first} ${evals.evaluator_last}`));
        });

        const collapsible1 = {
          collapsible: true,
          headers: eval_cols,
          data: eval_data,
          type: 'edit'
        };


        data_objects.push(createFullData(
          user.house_details.address_1,
          user.house_details.address_2,
          user.house_details.city,
          user.house_details.state,
          user.house_details.zip,
          [collapsible1]
        ));

        // console.log(data_objects);

      });

      setUserData(data_objects);
      setSearchData(data_objects);
      setDataLoaded(true);
      setData(data_objects);
    }
  };

  const fetch_evals = async () => {
    const response = await ApiClient.get(Requests.getAllHomeVideo);

    if (response.status == 200) {
      const data = response.data.user_house;
      const data_objects = [];

      data.map((user) => {
        const formattedNumber = Utils.formatPhoneNumber(user.user_data.phone);
        const homes = user.house_info;

        let homeData = [];
        homes.map((home, idx2) => {
          const address_1 = {
            label: home.address_1 ? home.address_1 : '-',
            href: `/home/${home.house_id}`,
            index: idx2,
            type: 'edit',
            video: user.video_info,
          };

          const address_2 = {
            label: home.address_2 ? home.address_2 : '-',
            href: `/home/${home.house_id}`,
            index: data.length,
            type: 'edit',
            video: user.video_info,
          };

          homeData.push(createHomeData(
            address_1,
            address_2,
            home.city,
            home.state,
            home.zip
          ));
        });

        const collapsible1 = {
          collapsible: true,
          headers: homes_cols,
          data: homeData
        };

        data_objects.push(
          createData(
            user.user_data.email,
            user.user_data.f_name,
            user.user_data.l_name,
            formattedNumber,
            [collapsible1]
          )
        );
      });
    }
  };

  useEffect(() => {
    setLoaded(true);
  }, []);

  useEffect(() => {
    if (loaded) {
      fetch_homes();
      fetch_evals();
    }
  }, [loaded]);

  const onSortClick = (col) => {
    let localSorted = sorted;

    if (lastSorted != col) {
      localSorted = false;
      setSorted(false);
    }

    console.log(data);
    if (col == 'City')
      setSearchData(Utils.sortUserDataArray(data, localSorted, 'city'));
    else if (col == 'State')
      setSearchData(Utils.sortUserDataArray(data, localSorted, 'state'));
    else if (col == 'Zip')
      setSearchData(Utils.sortUserDataArray(data, localSorted, 'zip'));


    setLastSorted(col);
    setSorted(!sorted);
  };



  /**
   * Handles dynamic array filtering
   * @param {*} e contains text string value
   */

  const onSearchFilter = (e) => {
    setSearchPage(e ? true : false);
    setSearchData(Utils.filterDataArray(data, e));
  };

  return (<>
    <div style={{ position: 'absolute', top: 0, right: 0 }}>
      <img width='150' height='150' src={logo} alt="My Image" /><br></br>
    </div>
    <SessionNav /><br />
    <div style={{ width: '100%' }} className="homeowner">
      <ThemeProvider theme={theme}>
        <Typography variant='h4'>Evaluations</Typography><br />
        {dataLoaded &&
          <Table
            cols={new_cols}
            rows={searchData}
            searchBar={true}
            onSearchFilter={onSearchFilter}
            onSortClick={onSortClick}
            searchPage={search}
            loading={api}
            pages
          />}
        {!dataLoaded &&
          <DataLoading dataFields={5} />
        }<br />
      </ThemeProvider>
    </div>
  </>);
};

export default HomeOwner;
