import React, { useState, useEffect } from 'react';

import { styled } from '@mui/material/styles';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell, { tableCellClasses } from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import Link from '@mui/material/Link';
import TextField from '@mui/material/TextField';
import TablePagination from '@mui/material/TablePagination';
import Typography from '@mui/material/Typography';
import Collapse from '@mui/material/Collapse';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import IconButton from '@mui/material/IconButton';
import Box from '@mui/material/Box';
import PersonAddAlt1Icon from '@mui/icons-material/PersonAddAlt1';

import SortByAlphaIcon from '@mui/icons-material/SortByAlpha';
import AddCircleOutlineSharpIcon from '@mui/icons-material/AddCircleOutlineSharp';

import PropTypes from 'prop-types';

import { ThemeProvider } from '@mui/material/styles';
import { useTheme } from '@mui/material/styles';

import '../styles/header.scss';
import { Tooltip } from '@mui/material';

const NO_SORT = {
  'Phone': true,
  '': true,
  'Address 1': true,
  'Address 2': true,
  'Zip': true,
};

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: theme.palette.common.white,
    color: theme.palette.common.black,
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 12,
  },
}));


const StyledTableRow = styled(TableRow)(({ theme, ...props }) => {
  let odd = props.odd == 'true' ? true : false;

  return {
    backgroundColor: odd ? theme.palette.action.hover : 'inherit',
    // hide last border
    '&:last-child td, &:last-child th': {
      border: 0,
    },
  };
});

Header.propTypes = {
  rows: PropTypes.array,
  cols: PropTypes.array,
  width: PropTypes.number,
  align: PropTypes.string,
  searchBar: PropTypes.bool,
  onSearchFilter: PropTypes.func,
  pages: PropTypes.bool,
  searchPage: PropTypes.bool,
  onSortClick: PropTypes.func,
  subTableHeader: PropTypes.string
};

Row.propTypes = {
  row: PropTypes.object,
  odd: PropTypes.string,
};

function Row(props) {
  const { row, odd } = props;
  const [open, setOpen] = useState(false);

  useEffect(() => {
    if (open) {
      setOpen(false);
    }
  }, [row]);

  return (
    <React.Fragment>
      {/* If the row is collapsible, create a dropdown arrow, also color the row component */}
      <StyledTableRow odd={odd} >
        {row.collapses ? (
          <StyledTableCell sx={{ padding: 0 }}>
            <IconButton
              aria-label="expand row"
              size="small"
              onClick={() => setOpen(!open)}
            >
              {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
            </IconButton>
          </StyledTableCell>
        ) : <TableCell />}
        {/* Map all of the array objects included in the row object */}
        {Object.values(row)
          .map((cellValue, index) => (
            <React.Fragment key={index}>
              {/* This fragment handles creating the links within a table such as: edit, delete */}
              {/*
               Links must be handled as an object, for example:

               const link = {
                label: 'edit',
                type: 'edit', // Note that having type be anything but false or undefined will work
                onClick: func(),
                href: /link
               }
               */}
              {!Array.isArray(cellValue) &&
                <StyledTableCell key={index} align="right">
                  {cellValue && cellValue.type && <Link
                    onClick={
                      () => {
                        if (cellValue.onClick) { cellValue.onClick(row, cellValue.user_id, cellValue.index); }
                      }}
                    href={cellValue.href}>{cellValue.label}
                  </Link>}
                  {cellValue && !cellValue.type && cellValue}
                </StyledTableCell>
              }
            </React.Fragment>
          ))}
      </StyledTableRow>
      {/* The row has been rendered, now we render the subtable */}
      <TableRow>
        {row.collapses && row.collapses
          .map((cellValue, index) => (
            <StyledTableCell key={index} style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={8}>
              <Collapse in={open} timeout='auto' unmountOnExit>
                <Box sx={{ margin: 1 }}>
                  <Typography variant="h6" gutterBottom component="div">
                    {cellValue.header}
                  </Typography>
                  <Table size="small" aria-label="purchases">

                    <TableHead>
                      <TableRow>
                        {/* Create Row Headers */}
                        {cellValue.headers.map((name, idx) => (
                          <TableCell key={idx}>{name}</TableCell>
                        ))}
                      </TableRow>
                    </TableHead>

                    {/* Map all of the subdata to their respsective columns */}
                    <TableBody>
                      {/* Map Each CellValue to Subdata */}
                      {Object.values(cellValue.data).map((data, idx) => (
                        <TableRow key={idx}>
                          {Object.values(data).map((dataVal, idx2) => {
                            /*
                             Note that even subtable rows can have links, formatted the same way as the table rows:

                            Links must be handled as an object, for example:

                            const link = {
                              label: 'edit',
                              type: 'edit', // Note that having type be anything but false or undefined will work
                              onClick: func(),
                              href: /link
                            }
                            */
                            if (dataVal && dataVal.type && dataVal.type !== 'button' && !(dataVal.label === '-')) {
                              return (
                                <StyledTableCell key={idx2}>
                                  <Link
                                    onClick={() => {
                                      if (dataVal.onClick) {
                                        dataVal.onClick(row, dataVal.user_id, dataVal.index, dataVal.home, dataVal.homeIdx, dataVal.eval_name);
                                      }
                                    }}
                                    href={dataVal.href}
                                  >
                                    {dataVal.label}
                                  </Link>
                                </StyledTableCell>
                              );
                            } else if (dataVal && dataVal.type !== 'button') {
                              return (
                                <StyledTableCell key={idx2}>
                                  {dataVal && dataVal.label ? '-' : dataVal}
                                </StyledTableCell>
                              );
                            } else {
                              <StyledTableCell key={idx2}>
                                -
                              </StyledTableCell>;
                            }
                          })}
                        </TableRow>
                      ))}
                      {/* Handles the + buttons at the bottom of the table */}
                      {cellValue?.data?.[cellValue.data.length - 1]?.add?.inputButton && (
                        <TableRow sx={{ border: 0 }}>
                          <TableCell sx={{ border: 0, width: 'fit-content' }}>
                            {cellValue.data[cellValue.data.length - 1].edit.userInfo.highestRole == 1 &&
                              <Tooltip title='Add Address to Homeowner' arrow>
                                <IconButton
                                  color='info'
                                  size='small'
                                  onClick={() => { cellValue.data[cellValue.data.length - 1].add.onClick(cellValue.data[cellValue.data.length - 1].edit.user_id); }}>
                                  <AddCircleOutlineSharpIcon />
                                </IconButton>
                              </Tooltip>
                            }
                            {cellValue.data[cellValue.data.length - 1].edit.userInfo.highestRole >= 2 &&
                              <Tooltip title='Connect to Existing Homeowner' arrow>
                                <IconButton
                                  color='info'
                                  size='small'
                                  onClick={() => { cellValue.data[cellValue.data.length - 1].add.onMake(cellValue.data[cellValue.data.length - 1].edit.user_id); }}>
                                  <PersonAddAlt1Icon />
                                </IconButton>
                              </Tooltip>
                            }
                          </TableCell>

                        </TableRow>
                      )}
                    </TableBody>

                  </Table>
                </Box>
              </Collapse>
            </StyledTableCell>
          ))
        }
      </TableRow >
    </React.Fragment >
  );
}

/**
 * Renders a React table that is custom styled containing:
 *
 * Props:
 * rows (array) - Array containing all data values to map into the table
 * cols (array) - Array containing all of the column names to map into the table
 * width (number) - Width of the table
 * align (string) - Alignment of the table (center, left, right)
 * searchBar(bool) - Include a search bar at the top of the table that allows for data filtering
 * onSearchFilter(func) - Callback function that handles text IO into searchBar
 * pages(bool) - Allow table to have multiple pages of data, rather than one large table
 * onSortClick(func) - Callback function handling the filtering of data
 * subTableHeader(string) - Title at the top of each subpage
 *
 * @param {*} props
 * @returns
 */
function Header(props) {
  const theme = useTheme();
  const width = props.width ? props.width : 900;
  const align = props.align ? props.align : 'right';

  const [page, setPage] = useState(0); // Page that the table is on
  const [rowsPerPage, setRowsPerPage] = useState(10); // Number of rows per page (user can change this)
  const [actualPage, setActualPage] = useState(0); // Actual page is for when values are being searched, and the page needs to be reset
  const [isData, setIsData] = useState(true); // Any data included in the table

  // Identify the number of rows to include in the table
  const pageRange = props.rows.slice(actualPage * rowsPerPage, actualPage * rowsPerPage + rowsPerPage);

  // Determine if their are any rows in the table
  if (props.rows.length <= 0 && isData == true) {
    setIsData(false);
  } else if (props.rows.length > 0 && isData == false) {
    setIsData(true);
  }

  // If the user is on a page that isn't page 0 while searching, they will be reset
  //  back to page 0
  if (props.searchPage && (actualPage > 0)) {
    setActualPage(0);
  }

  // Whenever the page hook changes, if there is a search page, set the page to 0 otherwise set it to the
  // previous page the user was on.
  useEffect(() => {
    setActualPage(props.searchPage ? 0 : page);
  }, [page, props.searchPage]);

  useEffect(() => {
    if (!props.pages) {
      setRowsPerPage(props.rows.length);
    }
  }, [props.rows]);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  return (
    <>
      <ThemeProvider theme={theme}>
        {/* If searchbar prop is true, this will be rendered */}
        {props.searchBar &&
          <TextField
            label="Search"
            variant="outlined"
            fullWidth
            onChange={e => props.onSearchFilter(e.target.value)}
            sx={{ mb: 3, boxShadow: 1, width: '60%' }}
          />
        }
        {/* Handles the changing of pages within a table */}
        {props.pages && (props.rows.length > 0) &&
          <TablePagination
            rowsPerPageOptions={[10, 25, 100]}
            component="div"
            count={props.rows.length}
            rowsPerPage={rowsPerPage}
            page={actualPage}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
          />
        }
        {/*  Renders all of the data in the table */}
        {isData &&
          <TableContainer component={Paper} sx={{ mb: 10, boxShadow: 3, width: '90%', overflowX: 'auto' }}>
            {/* Create a table component with small margins */}
            <Table size='small' sx={{ minWidth: width }} aria-label="customized table">
              <TableHead>
                {/* Creates the header row for the table (sorting is included) */}
                {/* NOTE: if you don't want a column sortable, add it to the NO_SORT array at the top */}
                <TableRow>
                  <TableCell />
                  {props.cols.map((col, idx) => (
                    <React.Fragment key={idx}>
                      <StyledTableCell align={align}>
                        {!NO_SORT[col] &&
                          <IconButton
                            sx={{ color: 'black' }}
                            size="small"
                            onClick={() => props.onSortClick(col)}>
                            <SortByAlphaIcon fontSize='6' />
                          </IconButton>
                        }
                        {col}
                      </StyledTableCell>
                    </React.Fragment>
                  ))}
                </TableRow>
              </TableHead>
              <TableBody>
                {/* Map all of the data elements to their respective rows */}
                {pageRange.map((row, idx) => (
                  <Row odd={(idx % 2 == 0).toString()} key={idx} row={row} />
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        }
        {/* If there is no data found, display a No Results text */}
        {!isData && <Typography sx={{ mt: 10, color: 'grey' }} variant='h5'> No Results</Typography>}
      </ThemeProvider>
    </>
  );
}
export default Header;
