import React, { } from 'react';

import Button from '@mui/material/Button';
import CircularProgress from '@mui/material/CircularProgress';

import PropTypes from 'prop-types';

SubmitButton.propTypes = {
  onClick: PropTypes.func,
  loading: PropTypes.bool,
  disabled: PropTypes.bool,
  label: PropTypes.string,
  sx: PropTypes.object
};

/**
 * A textfield customized with a loading circle
 *
 * @param {*} props
 * @returns
 */

function SubmitButton(props) {
  return (
    <>
      <Button
        disabled={props.disabled}
        variant="contained"
        onClick={props.onClick}
        sx={props.sx}
      >{props.label}
        {props.loading ? (
          <CircularProgress size={24} sx={{ ml: 1 }} color='inherit' />
        ) : null}
      </Button><br /><br />
    </>
  );
}
export default SubmitButton;
