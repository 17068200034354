import React, { useEffect, useState } from 'react';
import ApiClient from '../ApiClient';
import Requests from '../Requests';
import { Navigate } from 'react-router-dom';

import PasswordTextField from '../components/PasswordTextField';
import SubmitButton from '../components/SubmitButton';

import { IconButton, Typography } from '@mui/material';
import { createTheme, responsiveFontSizes, ThemeProvider } from '@mui/material/styles';
import FormControl from '@mui/material/FormControl';
import InputLabel from '@mui/material/InputLabel';
import OutlinedInput from '@mui/material/OutlinedInput';
import MuiAlert from '@mui/material/Alert';
import Checkbox from '@mui/material/Checkbox';
import FormControlLabel from '@mui/material/FormControlLabel';

import Navigation from '../components/Navigation';

import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import PasswordIcon from '@mui/icons-material/Password';

import logo from './logo.png';

// Error Component Styling
const Alert = React.forwardRef(function Alert(props, ref) {
  return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

// Center the Alert Message
const alertCSS = {
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center'
};

let theme = createTheme();
theme = responsiveFontSizes(theme);

let remember = false;

const Login = () => {
  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');

  // Material UI Alert Component Props
  const [severity, setSeverity] = React.useState('');
  const [displayAlert, setDisplayAlert] = React.useState(false);
  const [alertMessage, setAlertMessage] = React.useState('Login not Found');
  const [success, setSuccess] = React.useState(false);
  const [rememberMe, setRememberMe] = React.useState(false);

  const [apiCall, setApiCall] = React.useState(false);

  const [loaded, isLoaded] = React.useState(false);

  const [state, setState] = React.useState();

  useEffect(() => {
    const remString = localStorage.getItem('remember');
    const remBool = remString === 'true' ? true : false;

    let tempState;

    console.log('here');

    if (!remBool) {
      setState(sessionStorage);
      setRememberMe(false);
      remember = false;

      tempState = sessionStorage;
    } else {
      setState(localStorage);
      setRememberMe(true);
      remember = true;

      tempState = localStorage;
    }

    if (tempState.getItem('session')) {
      setSuccess(true);
    }
    isLoaded(true);

  }, [state]);

  const messages = {
    API: 'An Error Occurred on Our Server',
    '404': 'Login not Found',
    Empty: 'Missing Email or Password'
  };

  // Determines if a field is empty
  const check_empty = () => {
    return username == '' || password == '';
  };

  const remember_me = () => {
    remember = !remember;

    setRememberMe(remember);
    setState(remember ? localStorage : sessionStorage);
    localStorage.setItem('remember', remember);
  };

  const manual_login = async (username, password) => {
    const info = {
      email: username,
      pw: password
    };

    const response = await ApiClient.post(
      Requests.validateLogin,
      info
    );

    if (response) {
      if (!response.data.msg) {
        const session_token = response.data.session;

        state.setItem('session', session_token);
        state.setItem('userInfo', JSON.stringify(response.data.user_data));

        setSuccess(true);
      }
    }

    setApiCall(false);
  };

  const validate_login = async () => {
    if (check_empty()) {
      setSeverity('warning');
      setDisplayAlert(true);
      setAlertMessage(messages.Empty);

      return;
    }

    try {
      const info = {
        email: username,
        pw: password
      };

      setApiCall(true);

      const response = await ApiClient.post(
        Requests.validateLogin,
        info
      );

      console.log(response);

      if (response) {
        if (!response.data.msg) {
          const session_token = response.data.session;

          console.log(session_token);

          state.setItem('session', session_token);
          state.setItem('userInfo', JSON.stringify(response.data.user_data));

          setSuccess(true);

        } else {
          // Login Not Found.
          setSeverity('error');
          setDisplayAlert(true);
          setAlertMessage(messages['404']);
        }
      }

      setApiCall(false);
    } catch (err) {
      console.log(err);
    }
  };

  if (success) {
    // Show a loading indicator or something else to indicate the page is not ready yet
    return <Navigate to='/menu' />;
  }

  return (<>
    {loaded &&
      <div style={{ height: 'auto' }} className='login_body'>
        <ThemeProvider theme={theme}>
          <img width='300' height='300' src={logo} alt="My Image" /><br></br>
          {displayAlert && <Alert sx={alertCSS} severity={severity}> {alertMessage} </Alert>}<br />
          <Typography variant="h4">Account Login</Typography><br />
          <FormControl sx={{ m: 0, width: '25ch' }} variant="outlined">
            <InputLabel htmlFor="outlined-adornment-password">Email</InputLabel>
            <OutlinedInput
              required
              id="outlined-adornment-username"
              onChange={e => setUsername(e.target.value)}
              label="Password"
            />
          </FormControl><br />

          <PasswordTextField
            required
            id="outlined-adornment-password"
            onChange={e => setPassword(e.target.value)}
            label="Password"
          />
          <br />
          <FormControlLabel
            label='Remember Me'
            labelPlacement='start'
            control={
              <Checkbox onChange={() => { remember_me(); }} checked={rememberMe} />
            }
          />
          <br />
          <SubmitButton
            loading={apiCall}
            disabled={apiCall}
            onClick={() => validate_login()}
            label="login"
          />
          <br />
          <Navigation active='login' />
        </ThemeProvider>
      </div>
    }
  </>);
};

export default Login;
