
import React from 'react';

import Box from '@mui/material/Box';
import Skeleton from '@mui/material/Skeleton';

import PropTypes from 'prop-types';


MaxWidthDialog.propTypes = {
  dataFields: PropTypes.number,
};

/**
 * Displays a loading skeleton while the page is loading.
 *
 * Props:
 * dataFields (number) - How many loading skeletons the user wants.
 */
export default function MaxWidthDialog(props) {
  return (<>
    {Array.from({ length: props.dataFields }).map((_, index) => (
      <Box key={index} sx={{ width: .6 }}>
        <Skeleton animation='pulse' variant='rectangular' height={50} sx={{ mb: 1.5 }} />
      </Box>
    ))}
  </>);
}
